import { ENTER_CHAR_CODE, SPACE_CHAR_CODE } from '@utils';

export const getCheckboxOnKeyPress = (value, setValue, checkBoxRef) => e => {
  if (e.charCode === SPACE_CHAR_CODE) {
    setValue(!value);
  } else if (e.charCode === ENTER_CHAR_CODE) {
    checkBoxRef.props.inputProps.onKeyPress({
      charCode: ENTER_CHAR_CODE,
      currentTarget: {
        attributes: { inputId: { nodeValue: checkBoxRef.props.inputProps.inputId } }
      }
    });
  }
};
