import API from '@sm/api';
import mock from '../mock';

const activeneProductSearchResult = {
  brands: [
    {
      id: 21,
      name: 'Activene',
      products: 10
    }
  ],
  parent_products: [
    {
      id: 22,
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 1,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC',
      seller_sku: 'BO1LVWG3WW'
    },
    {
      id: 23,
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 2,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC'
    },
    {
      id: 24,
      name: 'Activene Plantar Fasciitis Socks - Foot Sleeves ',
      marketplace: 3,
      variations: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'RO2LASG3QC'
    }
  ],
  products: [
    {
      id: 25,
      type: 'child',
      name:
        'ACTIVENE Arnica Gel Cream - with Menthol and MSM | Pain Relief for Joint, Tendon & Muscle Pains | Chosen by sufferers of Arthritis, Fibromyalgia, Plantar Fasciitis, Knee, Shoulder, Neck, Back Ache 3oz',
      marketplace: 3,
      smallImage: 'http://ecx.images-amazon.com/images/I/41utf8C5ibL._SL75_.jpg',
      asin: 'B012A5Z89K',
      sku: 'UF-KPKM-TMSZ'
    }
  ]
};

const zegaroProductSearch = {
  brands: [],
  parent_products: [],
  products: [
    {
      id: 14490709,
      asin: 'H515DFT59R',
      marketplace: 3,
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      sku: 'GG-51B4-DTG2'
    },
    {
      id: 14490716,
      asin: 'A554K46TJL',
      marketplace: 3,
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      sku: 'BU-4HE3-PV122'
    }
  ]
};

/* function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
} */

// mock.onGet(API.products.search).reply(async () => {
//   // console.log('-------------- delayed API for testing loading components --------------');
//   // await sleep(5000);
//   return [200, activeneProductSearchResult];
// });

const bookmarksDb = [];

mock.onGet(API.products.bookmarks).reply(() => {
  return [200, bookmarksDb];
});

mock.onPost(API.products.bookmarks).reply(() => {
  return [201, bookmarksDb[0]];
});

mock.onPut(new RegExp(`${API.products.bookmarks}/.+`)).reply(() => {
  return [200, bookmarksDb[0]];
});

mock.onGet(API.products.search).reply(() => {
  return [200, zegaroProductSearch];
});

mock.onGet(API.products.search, { params: { searchText: 'zegaro' } }).reply(() => {
  return [200, zegaroProductSearch];
});
