import { withStyles } from '@material-ui/core/styles';
import TextFieldIconV1, { INPUT_ROOT_STYLES } from '@components/inputs/TextFieldIcon';

const getInputLabelStyles = () => {
  return {
    ...INPUT_ROOT_STYLES,
    '& .MuiOutlinedInput-root fieldset': {
      borderRadius: '0.75rem',
      top: '0'
    },
    '& .MuiInputLabel-root.MuiFormLabel-filled': { display: 'none' },
    '& .MuiInputLabel-root.Mui-focused': { display: 'none' },
    '& legend': { display: 'none' }
  };
};

const getStyles = () => () => ({
  root: getInputLabelStyles(),
  formControl: { left: '0.25rem', top: '0rem' },
  inputLabel: { fontSize: '0.875rem', '&.shrink': { left: 0, top: 0 } },
  disabled: {}
});

export default withStyles(getStyles())(TextFieldIconV1);
