import React from 'react';
import PropTypes from 'prop-types';

import { isSmallSize } from '@sm/WindowSizes';
import { GLOBAL_MK } from '@sm/Flags';
import { SELECTOR_VIEW_HEIGHT } from '@sm/layout';
import DatePicker from '@components/date-picker';
import DownloadButton from '@components/buttons/DownloadButton';

import { BookmarkIconButton } from 'seller/containers/bookmark-search';
import MarketplaceSelector from './MarketplaceSelector';
import ProductSearchInput from './ProductSearchInput';
import { exportCSV, getCsvFileName } from '../products-selector-view/csvHelpers';

const LeftView = props => {
  const {
    onSearchFocus,
    marketplaces,
    marketplaceID,
    onMarketplaceChanged,
    searchPlaceholder,
    hide,
    hideProductSearch,
    widget,
    hideMarketplaceSelector
  } = props;
  if (hide) return null;
  const searchInputPlaceholder = isSmallSize()
    ? 'Search...'
    : 'Search by Brand, Product, SKU, ASIN';

  const content = (
    <>
      {!hideMarketplaceSelector && (
        <MarketplaceSelector
          marketplaces={marketplaces}
          marketplaceID={marketplaceID}
          onMarketplaceChanged={onMarketplaceChanged}
        />
      )}
      {widget}
      {!hideProductSearch && (
        <>
          <BookmarkIconButton />
          <ProductSearchInput
            {...props}
            className="ml-3"
            onFocus={onSearchFocus}
            placeholder={searchPlaceholder || searchInputPlaceholder}
          />
        </>
      )}
    </>
  );

  return <div className="flex flex-1 justify-start items-center pl-2 md:pl-0">{content}</div>;
};

const SelectorViewComponent = ({
  setInput,
  activateSearch,
  setMarketplace,
  setDateRange,
  setCompareDateRange,
  profile,
  dateRange,
  compareDateRange,
  dataTableFilteredData,
  download,
  searchPlaceholder,
  fileName,
  columns,
  playButton,
  keyword,
  marketplaceID,
  hideLeftView,
  hideProductSearch,
  hideDatePicker,
  hideMarketplaceSelector,
  isCompareEnable,
  leftView,
  hideGlobalMk,
  leftViewWidget,
  datePickerProps
}) => {
  const onSearchInputChange = _keyword => setInput(_keyword);
  const onSearchFocus = () => activateSearch(true);
  const marketplaces =
    profile.marketplaces?.filter(mk => (hideGlobalMk ? mk !== GLOBAL_MK : true)) || [];
  const csvFileName = getCsvFileName({ fileName, marketplaceId: marketplaceID });
  return (
    <div
      style={{ height: SELECTOR_VIEW_HEIGHT }}
      className="flex flex-row flex-shrink-0 relative 10 box-shadow-light overflow-visible bg-white lg:px-6 xs:px-2 justify-between items-center 10 z-20"
    >
      {leftView || (
        <LeftView
          onChange={onSearchInputChange}
          openSearchDialog={() => {}}
          onSearchFocus={onSearchFocus}
          keyword={keyword}
          marketplaces={marketplaces}
          marketplaceID={marketplaceID}
          onMarketplaceChanged={setMarketplace}
          searchPlaceholder={searchPlaceholder}
          hide={hideLeftView}
          hideProductSearch={hideProductSearch}
          hideMarketplaceSelector={hideMarketplaceSelector}
          widget={leftViewWidget}
        />
      )}
      {playButton}
      {download && (
        <DownloadButton
          className="mr-8 hidden md:flex"
          paddingY="py-0"
          fileName={getCsvFileName({ fileName, marketplaceId: marketplaceID })}
          data={dataTableFilteredData}
          columns={columns}
          onClick={data => exportCSV(data, csvFileName, columns)}
        >
          {download}
        </DownloadButton>
      )}
      {!hideDatePicker && (
        <DatePicker
          className="w-auto flex-shrink-0"
          lifeTime={profile.start}
          initRange={dateRange}
          compareDateRange={compareDateRange}
          onRangeChange={setDateRange}
          onCompareRangeChange={setCompareDateRange}
          marketplaces={profile.marketplaces}
          marketplaceID={marketplaceID}
          isCompareEnable={isCompareEnable}
          {...datePickerProps}
        />
      )}
    </div>
  );
};

SelectorViewComponent.propTypes = {
  activateSearch: PropTypes.func,
  setInput: PropTypes.func,
  setMarketplace: PropTypes.func,
  setDateRange: PropTypes.func.isRequired,
  setCompareDateRange: PropTypes.func,
  dateRange: PropTypes.shape({
    from: PropTypes.instanceOf(Object).isRequired,
    to: PropTypes.instanceOf(Object).isRequired
  }).isRequired,
  compareDateRange: PropTypes.shape({
    from: PropTypes.instanceOf(Object).isRequired,
    to: PropTypes.instanceOf(Object).isRequired
  }),
  keyword: PropTypes.string,
  profile: PropTypes.instanceOf(Object).isRequired,
  marketplaceID: PropTypes.number,
  download: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  fileName: PropTypes.string,
  hideLeftView: PropTypes.bool,
  hideDatePicker: PropTypes.bool,
  hideProductSearch: PropTypes.bool,
  hideMarketplaceSelector: PropTypes.bool,
  isCompareEnable: PropTypes.bool,
  datePickerProps: PropTypes.instanceOf(Object),
  dataTableFilteredData: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Object),
  leftViewWidget: PropTypes.instanceOf(Object),
  playButton: PropTypes.node,
  leftView: PropTypes.instanceOf(Object),
  hideGlobalMk: PropTypes.bool
};

SelectorViewComponent.defaultProps = {
  blur: false,
  hideGlobalMk: false,
  download: '',
  keyword: '',
  searchPlaceholder: '',
  hideLeftView: false,
  hideProductSearch: false,
  hideMarketplaceSelector: false,
  hideDatePicker: false,
  activateSearch: () => {},
  setInput: () => {},
  setMarketplace: () => {},
  marketplaceID: null,
  isCompareEnable: false,
  datePickerProps: {},
  compareDateRange: null,
  playButton: null,
  fileName: '',
  dataTableFilteredData: [],
  columns: {},
  leftView: null,
  leftViewWidget: null,
  setCompareDateRange: () => {}
};

export default SelectorViewComponent;
