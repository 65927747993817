import React from 'react';
import PropTypes from 'prop-types';
import { Sidebar as SidebarV1 } from '@components';

const Sidebar = props => {
  return <SidebarV1 {...props} getWidth={size => size} />;
};

Sidebar.propTypes = {
  isSearchDialogOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  side: PropTypes.string,
  direction: PropTypes.string
};

Sidebar.defaultProps = {
  side: 'left',
  direction: 'right'
};

export default Sidebar;
