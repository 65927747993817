import React from 'react';
import PropTypes from 'prop-types';

const WebImg = ({
  className,
  imgClassName,
  src,
  srcMobile,
  srcWebp,
  srcMobileWebp,
  mobileMediaQuery,
  imgType,
  alt
}) => {
  return (
    <picture className={className}>
      {!!srcMobileWebp && (
        <source
          media={mobileMediaQuery}
          srcSet={srcMobileWebp}
          type="image/webp"
          className={imgClassName}
        />
      )}
      {!!srcMobile && (
        <source
          media={mobileMediaQuery}
          srcSet={srcMobile}
          type={imgType}
          className={imgClassName}
        />
      )}
      <source type="image/webp" srcSet={srcWebp} className={imgClassName} />
      <img src={src} alt={alt} className={imgClassName} />
    </picture>
  );
};

WebImg.propTypes = {
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  src: PropTypes.string,
  srcMobile: PropTypes.string,
  srcWebp: PropTypes.string,
  srcMobileWebp: PropTypes.string,
  mobileMediaQuery: PropTypes.string,
  imgType: PropTypes.string,
  alt: PropTypes.string
};

WebImg.defaultProps = {
  className: '',
  imgClassName: '',
  src: '',
  srcMobile: '',
  srcWebp: '',
  srcMobileWebp: '',
  mobileMediaQuery: '(max-width:500px)',
  imgType: 'image/png',
  alt: ''
};

export default WebImg;
