import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import BarsIcon from './img/common/ic_bars.svg';

function BurgerMenu(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      className="h-12 w-12 flex justify-center items-center xl:hidden p-4"
    >
      <img src={BarsIcon} alt="bars-icon" className="" />
    </IconButton>
  );
}

BurgerMenu.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default BurgerMenu;
