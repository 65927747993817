import { createRange, dateToStr } from '@utils';

export const HOST = process.env.REACT_APP_DOMAIN;

const API_URL_V2 = '/api/v2';
const API_URL_V2_1 = '/api/v2.1';
const API_URL_V2_2 = '/api/v2.2';
const BETA_API_URL = '/api/beta-2';

export const BASE_URL_V2 = HOST + API_URL_V2;
export const BASE_URL_V2_1 = HOST + API_URL_V2_1;
export const BASE_URL_V2_2 = HOST + API_URL_V2_2;
const BETA_BASE_URL = HOST + BETA_API_URL;

export const BETA_API = {
  metrix: {
    totals: `${BETA_BASE_URL}/metrix/totals`
  },
  products: {
    sales: `${BETA_BASE_URL}/products/product_metrics`
  }
};

const API = {
  products: {
    search: `${BASE_URL_V2}/products/search`,
    sales: `${BASE_URL_V2}/products/product_metrics`,
    list: `${BASE_URL_V2}/products/list`,
    bookmarks: `${BASE_URL_V2}/products/bookmarks`,
    hCog: `${BASE_URL_V2}/products/history-cog`,
    inventory: `${BASE_URL_V2}/products/inventory`
  },
  metrix: {
    totals: `${BASE_URL_V2}/metrix/totals`,
    chart: `${BASE_URL_V2}/metrix/chart`,
    breakdown: `${BASE_URL_V2}/metrix/breakdown`
  },
  notes: `${BASE_URL_V2}/notes`,
  user: {
    profile: `${BASE_URL_V2}/user/profile`,
    accounts: `${BASE_URL_V2}/user/accounts`,
    password: `${BASE_URL_V2}/user/password`,
    mwsToken: `${BASE_URL_V2}/user/update_mws_token`,
    avatar: `${BASE_URL_V2}/user/avatar`
  },
  orders: {
    list: `${BASE_URL_V2}/orders/list`,
    realBreakdown: `${BASE_URL_V2}/orders/real`,

    // FIXME: remove at production,
    breakdown: `${BASE_URL_V2}/orders/breakdown`
  },
  emailTool: {
    messageTemplates: `${BASE_URL_V2}/efollowup/messagetemplate`,
    campaigns: `${BASE_URL_V2}/efollowup/campaign`,
    messages: `${BASE_URL_V2}/efollowup/message`,
    requestReview: `${BASE_URL_V2}/efollowup/request_review`,
    blacklist: `${BASE_URL_V2}/efollowup/blacklist`,
    sendEmail: `${BASE_URL_V2}/efollowup/send_email`,
    dynamicVariables: `${BASE_URL_V2}/efollowup/messagetemplate_variables`
  },
  expenses: {
    root: `${BASE_URL_V2}/expenses/`
  },
  advertising: {
    campaigns: `${BASE_URL_V2_1}/advertising/campaigns/`
  },
  refunds: {
    list: `${BASE_URL_V2}/refunds/list`
  },
  vat: `${BASE_URL_V2}/vat`,
  reports: `${BASE_URL_V2}/reports/`,
  login: `${BASE_URL_V2}/signup/api-token-auth/`,
  refreshToken: `${BASE_URL_V2}/signup/api-token-refresh/`,
  signup: `${BASE_URL_V2}/signup/signup`,
  password: {
    reset: `${BASE_URL_V2}/auth/password-reset`,
    resetDone: `${BASE_URL_V2}/auth/password-reset-done`
  },
  get_irp: `${BASE_URL_V2}/signup/get_irp_link`,
  addSellerAccount: `${BASE_URL_V2}/signup/add-seller-account`,
  systemNotification: `${BASE_URL_V2}/notifications/system`,
  billing: {
    addCC: `${BASE_URL_V2}/billing/add-cc/`,
    getKey: `${BASE_URL_V2}/billing/get-key/`,
    history: `${BASE_URL_V2}/billing/history/`,
    last_30d_plan: `${BASE_URL_V2}/billing/last_30d_plan`
  },
  get_lwa_state: `${BASE_URL_V2}/lwa/get_lwa_state/`,
  agency_clients_summary: `${HOST}/summary/user`,
  agency_clients: `${HOST}/agency/users/`,
  agency_clients_bulk: `${HOST}/agency/create_users`,
  shopify: {
    sign_up: `${BASE_URL_V2}/shopify/sign-up/`,
    shop: `${BASE_URL_V2}/shopify/shop/`
  },
  convert_engine: {
    pnl_converter: `${HOST}/convert_engine/pnl_converter`
  },
  monthly_brand_review: {
    root: `${HOST}/apps/mbr/`,
    chart: `${HOST}/apps/mbr/chart/`
  },
  pnl_dashboard: {
    metrics: `${HOST}/apps/pnl_dashboard/metrics`,
    top_sales_products: `${HOST}/apps/pnl_dashboard/top_sales_products`
  },
  insights: {
    chart: `${HOST}/apps/insights/chart`,
    objectives: `${HOST}/apps/insights/objectives`,
    analyze: `${HOST}/apps/insights/analyze`,
    prompts: `${HOST}/apps/insights/prompts`,
    details: `${HOST}/apps/insights/details`,
    products: {
      analysis: `${HOST}/apps/insights/products/analysis`
    },
    products_metrics: `${HOST}/apps/insights/products_metrics`,
    chat: `${HOST}/apps/insights/chat`
  },
  cms: {
    learn_more: {
      posts: `${HOST}/apps/cms/learn_more/posts`
    }
  }
};

export const API_V2_1 = {
  metrix: {
    totals: `${BASE_URL_V2_1}/metrix/totals`
  },
  products: {
    sales: `${BASE_URL_V2_1}/products/product_metrics`
  },
  searchQueryPerformance: {
    products: `${BASE_URL_V2_1}/search-query-performance/products`,
    status: `${BASE_URL_V2_1}/search-query-performance/status`,
    accounts: `${BASE_URL_V2_1}/search-query-performance/accounts`
  }
};

export const API_V2_2 = {
  metrix: {
    totals: `${BASE_URL_V2_2}/metrix/totals`
  }
};

export default API;

export const rangeToAPI = dateRange => {
  return createRange(dateToStr(dateRange.from), dateToStr(dateRange.to));
};

export const selectedProductsToAPI = data => {
  let brandIds = [];
  let productIds = [];
  let parentIds = [];

  data.forEach(item => {
    if (item.type === 'brand') {
      brandIds.push(item.name); // TODO: rename brandIds to brands
    } else if (item.type === 'parent') {
      parentIds.push(item.id);
    } else {
      productIds.push(item.id);
    }
  });
  brandIds = brandIds.join();
  productIds = productIds.join();
  parentIds = parentIds.join();

  return { brandIds, productIds, parentIds };
};

export const USER_ONBOARDING_STATUS = 'onboarding';
export const SHOPIFY_ONBOARDING_STATUS = 'shopify-onboarding';
export const USER_INCOMPLETED_STATUS = 'Incompleted';
export const USER_CANCELED_STATUS = 'Canceled';
export const USER_PAST_DUE_STATUS = 'Past Due';
export const USER_ACTIVE_STATUS = 'Active';
export const USER_TRIALING_STATUS = 'Trialing';
export const USER_TRIAL_EXPIRED = 'Trial Expired';
export const USER_FULL_ACCESS_FOR_FREE_STATUS = 'Full Access For Free';

export const UNKNOWN_PRODUCT = 'Unknown product';

export const ACTIVE_USER_STATUSES = [
  USER_TRIALING_STATUS,
  USER_ACTIVE_STATUS,
  USER_FULL_ACCESS_FOR_FREE_STATUS
];

export const USER_STATUS_LIST = [
  USER_ONBOARDING_STATUS,
  USER_INCOMPLETED_STATUS,
  USER_CANCELED_STATUS,
  USER_PAST_DUE_STATUS,
  USER_TRIALING_STATUS,
  USER_TRIAL_EXPIRED
];

const USER_ONBOARDING_STATUSES = [USER_ONBOARDING_STATUS, SHOPIFY_ONBOARDING_STATUS];

export const isOnboarding = profile => USER_ONBOARDING_STATUSES.includes(profile.status);

export const isIncompleted = profile => profile.status === USER_INCOMPLETED_STATUS;

export const isFullAccessForFree = profile => profile?.status === USER_FULL_ACCESS_FOR_FREE_STATUS;

export const isTrialExpired = profile => profile.status === USER_TRIAL_EXPIRED;

export const isInactive = profile => {
  const { subscription } = profile;
  if (!subscription) {
    return false;
  }

  return [USER_CANCELED_STATUS, USER_PAST_DUE_STATUS, USER_TRIAL_EXPIRED].includes(profile.status);
};

export const isCanceled = profile => {
  return profile.status === USER_CANCELED_STATUS;
};

export const isPastDue = profile => {
  return profile.status === USER_PAST_DUE_STATUS;
};

export const isTrialing = profile => {
  return profile.status === USER_TRIALING_STATUS;
};

export const DEFAULT_REQUEST_TIMEOUT = 90000;
export const LONG_REQUEST_TIMEOUT = 600000;
export const DASHBOARD_PRODUCT_LIST_PULLING_TIMEOUT = 2.5 * 60 * 1000; // 2.5 minutes

export const POSITIVE_DECREASE_FIELDS = ['acos', 'tacos'];

export const APP_VERSION_HTTP_HEADER = 'X-Client-Version';
export const GLOBAL_NOTIFICATION_HTTP_HEADER = 'X-System-Notification';

export const TOKEN_EXPIRATION_TIMEOUT = 7;

export const QUERY_STRING_LENGTH_LIMIT = localStorage.getItem('query_len_limit') || 3900; // Limit of query string length in server API software
