import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { isMediumSize, isLargeSize } from '@sm/WindowSizes';

const dialogWidth = isLargeSize() ? '35%' : '30%';

const Sidebar = props => {
  const { isSearchDialogOpened, handleClose, size, children, side, direction, getWidth } = props;

  const useStyles = makeStyles(() => ({
    leftDialog: {
      width: getWidth(size),
      height: '100%',
      position: 'absolute',
      [side]: 0,
      'z-index': 16
    },
    backdrop: {
      zIndex: 15,
      [side]: 0,
      position: 'absolute',
      width: '100%'
    }
  }));
  const classes = useStyles();

  return (
    <>
      <Slide direction={direction} in={isSearchDialogOpened} mountOnEnter unmountOnExit>
        <div className={classes.leftDialog}>{children}</div>
      </Slide>
      <Fade in={isSearchDialogOpened} mountOnEnter unmountOnExit>
        <Backdrop className={classes.backdrop} open={isSearchDialogOpened} onClick={handleClose} />
      </Fade>
    </>
  );
};

Sidebar.propTypes = {
  isSearchDialogOpened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  side: PropTypes.string,
  direction: PropTypes.string,
  size: PropTypes.string,
  getWidth: PropTypes.func
};

Sidebar.defaultProps = {
  side: 'left',
  direction: 'right',
  size: '',
  getWidth: size => (isMediumSize() ? '100%' : size || dialogWidth)
};

export default Sidebar;
