import React from 'react';
import { withRouter } from 'react-router-dom';
import { ImageObserver } from 'seller/containers/observer';
import HomepageHeader from './HomepageHeader';
import HomepageFooter from './HomepageFooter';
import UnderHeaderBlock from './UnderHeaderBlock';
import { HEADER_HEIGHT } from './consts';

function SuccessStoryPage() {
  const title = (
    <>
      <span>See How SellerMetrix Drives </span>
      <span className="text-grad-1 playfair_display italic">Success</span>
    </>
  );

  return (
    <div className="flex justify-center h-full">
      <div style={{ paddingTop: HEADER_HEIGHT }} className="relative h-full flex-grow">
        <ImageObserver
          className="w-full flex flex-col items-center text-center xl:text-left"
          loadingClassname="overflow-hidden absolute w-screen h-screen"
        >
          <HomepageHeader />
          <UnderHeaderBlock
            title={title}
            description="See how SellerMetrix drives growth and delivers results through real
            customer success stories. Discover the impact it can have on your business."
          />
          <HomepageFooter />
        </ImageObserver>
      </div>
    </div>
  );
}

SuccessStoryPage.propTypes = {};

export default withRouter(SuccessStoryPage);
