import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import { BackIcon } from '@componentsV2';
import { isSmallSize } from '@sm/WindowSizes';

const BackIconButton = ({ onClick, className }) => {
  const size = isSmallSize() ? '40' : '52';
  return (
    <ButtonBase
      variant="text"
      className={classNames('rounded-xl hover:shadow-lg transition duration-200', className)}
      size="small"
      onClick={onClick}
    >
      <BackIcon width={size} height={size} />
    </ButtonBase>
  );
};

BackIconButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

BackIconButton.defaultProps = {
  onClick: () => {},
  className: ''
};

export default BackIconButton;
