import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Menu from '@material-ui/core/Menu';
import { withStyles, MenuItem } from '@material-ui/core';
import Colors from 'styles/colors';

export const BETA_LABEL = (
  <div className="ml-2 text-xxs bg-green text-white rounded px-1 py-05 font-medium">BETA</div>
);

const ProfileMenuItem = withStyles({
  root: {
    minHeight: '40px',
    paddingLeft: '0.6875rem !important',
    paddingRight: '0.6875rem !important'
  }
})(MenuItem);

function IconsMenu(props) {
  const { menuList, className, onOptionClick, selected, onClose, ...others } = props;

  function getIconColor(title) {
    let iconColor = title === selected ? Colors.white.default : Colors.black.default;

    // FIXME: remove this crunch
    if (title === 'Log Out') {
      iconColor = Colors.red.default;
    }

    return iconColor;
  }

  return (
    <div className="flex w-full justify-right">
      <Menu
        getContentAnchorEl={null}
        className={classNames('sm-z-1600', className)}
        aria-haspopup="true"
        MenuListProps={{ style: { padding: '0.5rem' } }}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        {...others}
      >
        {menuList.map(option => {
          const iconColor = getIconColor(option.title);

          const titleClassNames = classNames('text-sm leading-tight', {
            'text-white': selected === option.title,
            'text-black-lighter': selected !== option.title
          });

          const textClassNames = classNames('text-xs leading-tight', {
            'text-white': selected === option.title,
            'text-gray-dark opacity-75': selected !== option.title
          });

          return (
            <ProfileMenuItem
              key={option.id}
              onClick={option.onClick || (() => onOptionClick(option.title))}
              className={classNames('rounded', { 'bg-grad': selected === option.title })}
            >
              <div className="flex items-center">
                <option.img fill={iconColor} className="mr-4 sm-h-14px" />
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <p className={titleClassNames}>{option.title}</p>
                    {option.beta && BETA_LABEL}
                  </div>
                  <p className={textClassNames}>{option.desc}</p>
                </div>
              </div>
            </ProfileMenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

IconsMenu.propTypes = {
  menuList: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string,
  onOptionClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

IconsMenu.defaultProps = {
  className: '',
  selected: null
};

export default IconsMenu;
