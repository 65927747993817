const Colors = {
  white: {
    default: '#fff',
    blue: '#f5f7ff',
    dark: '#e9eaf2',
    gray: '#fafafa',
    green: '#ddf7ee',
    yellow: '#fef7e3'
  },
  black: {
    lighter: '#363636',
    light: '#272B30',
    default: '#202124'
  },
  yellow: {
    transparent: '#f9ad1926',
    lighter: '#fac845',
    light: '#fabe28',
    default: '#f9ad19',
    dark: '#ef7401'
  },
  gray: {
    transparent: '#e9eaf2',
    lighter: '#d6daeb',
    light: '#eaecf5',
    default: '#c3c6d6',
    dark: '#878994',
    black: '#515258',
    darkest: '#232323'
  },
  green: {
    lighter: '#1bcb89',
    light: '#5CEB59',
    emerald: '#19C48F',
    default: '#2fcc2c'
  },
  red: {
    pink: '#fbe5e3',
    lighter: '#e15241',
    default: '#e03131'
  },
  blue: {
    default: '#476aac'
  },
  socialnetwork: {
    facebook: '#3b5998',
    google: '#dd5347'
  },
  violet: {
    white: '#f7f8fb',
    lighter: '#dce2f9',
    default: '#c9c2f7',
    dark: '#506fdf'
  },
  grad: {
    first: 'linear-gradient(315deg, #ef7401 0%, #f9ad19 100%)',
    second: 'linear-gradient(135deg, #ef7401 0%, #efa413 100%)'
  }
};

export default Colors;
