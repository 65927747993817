import {
  SET_MOBILE_APP_BANNER_SKIPPED,
  SET_ACCESS_DENIED_WARNING_SHOW,
  SET_SKIPPED_DISCONNECTED_ACCOUNTS,
  SET_TABLE_CONFIG,
  SET_REPORTS_CONFIG,
  SET_HIDE_PRODUCTS_WITHOUT_FINANCES,
  SET_SKIPPED_PPC_NEEDS_RECONNECT,
  SET_SP_API_EXPIRED_WARNING_SHOW,
  SET_SP_API_EXPIRED_WARNING_SKIPPED,
  PRODUCT_LEVEL_PROFIT_AND_LOSS_BANNER_SKIPPED
} from '../saga/userPreferences';
import { USER_LOGOUT } from '../actions';

const initialState = {
  accessDeniedBannerShow: false,
  accessDeniedSkipped: false,
  ppcNeedsReconnectSkipped: false,
  spApiExpiredWarningShow: false,
  spApiExpiredWarningSkipped: false,
  productLevelProfitAndLossBannerSkipped: false,
  accountsWarningShow: false,
  mobileAppBannerSkipped: false,
  tablesConfig: {},
  reports: {},
  beta: {
    unitsEconomics: false
  },
  hideProductsWithoutFinances: false
};
export const userPreferencesSelector = state => state.userPreferences;

const userPreferences = (state = initialState, action) => {
  switch (action.type) {
    case SET_SKIPPED_DISCONNECTED_ACCOUNTS: {
      return { ...state, accessDeniedSkipped: action.payload };
    }
    case SET_ACCESS_DENIED_WARNING_SHOW: {
      return { ...state, accessDeniedBannerShow: action.payload };
    }
    case SET_TABLE_CONFIG: {
      const { id, config } = action;
      return { ...state, tablesConfig: { ...state.tablesConfig, [id]: config } };
    }
    case SET_MOBILE_APP_BANNER_SKIPPED: {
      return { ...state, mobileAppBannerSkipped: action.payload };
    }
    case SET_REPORTS_CONFIG: {
      return { ...state, reports: { ...state.reports, ...action.payload } };
    }
    case USER_LOGOUT: {
      return { ...initialState, mobileAppBannerSkipped: state.mobileAppBannerSkipped };
    }
    case SET_HIDE_PRODUCTS_WITHOUT_FINANCES: {
      return { ...state, hideProductsWithoutFinances: action.payload };
    }
    case SET_SKIPPED_PPC_NEEDS_RECONNECT: {
      return { ...state, ppcNeedsReconnectSkipped: action.payload };
    }
    case SET_SP_API_EXPIRED_WARNING_SHOW: {
      return { ...state, spApiExpiredWarningShow: action.payload };
    }
    case SET_SP_API_EXPIRED_WARNING_SKIPPED: {
      return { ...state, spApiExpiredWarningSkipped: action.payload };
    }
    case PRODUCT_LEVEL_PROFIT_AND_LOSS_BANNER_SKIPPED: {
      return { ...state, productLevelProfitAndLossBannerSkipped: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default userPreferences;
