import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { compareFlatArray } from '@utils';
import { BookmarksSidebar } from 'seller/containers/bookmark-search';
import { setInput, setSelectedProducts as setSelectedProductsAction } from '../../store/actions';
import SearchWidget from './SearchWidget';

const SearchWidgetContainer = props => {
  const {
    productSearch,
    setProductSearchInput,
    setSelectedProducts,
    nonSidebarMode,
    hideBookmarksSidebar,
    maxProductsCount,
    onMaxProductCountReached
  } = props;

  const searchWidget = productSearch.active ? (
    <SearchWidget
      nonSidebarMode={nonSidebarMode}
      data={productSearch.products}
      selectedData={productSearch.selectedProducts}
      open={productSearch.active}
      loading={productSearch.loading}
      keyword={productSearch.keyword}
      onCommitSelection={newSelectedProducts => {
        setProductSearchInput('');
        const { selectedProducts } = productSearch;
        const newIds = newSelectedProducts.map(i => i.id);
        const oldIds = selectedProducts.map(i => i.id);
        if (compareFlatArray(newIds, oldIds)) return;
        setSelectedProducts(newSelectedProducts);
      }}
      onCancel={() => setProductSearchInput('')}
      maxProductsCount={maxProductsCount}
      onMaxProductCountReached={onMaxProductCountReached}
    />
  ) : null;

  return (
    <>
      {searchWidget}
      {!hideBookmarksSidebar && (
        <BookmarksSidebar
          maxProductsCount={maxProductsCount}
          onMaxProductCountReached={onMaxProductCountReached}
        />
      )}
    </>
  );
};

SearchWidgetContainer.propTypes = {
  productSearch: PropTypes.instanceOf(Object).isRequired,
  setProductSearchInput: PropTypes.func.isRequired,
  setSelectedProducts: PropTypes.func.isRequired,
  nonSidebarMode: PropTypes.bool,
  hideBookmarksSidebar: PropTypes.bool,
  maxProductsCount: PropTypes.number,
  onMaxProductCountReached: PropTypes.func
};

SearchWidgetContainer.defaultProps = {
  nonSidebarMode: false,
  hideBookmarksSidebar: false,
  maxProductsCount: null,
  onMaxProductCountReached: () => {}
};

function mapStateToProps(state) {
  return {
    productSearch: state.productSearch
  };
}

export default connect(mapStateToProps, {
  setProductSearchInput: setInput,
  setSelectedProducts: setSelectedProductsAction
})(SearchWidgetContainer);
