import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from './routing';
import { RouteView } from './layouts';
import LoginWindow from './pages/login-window-v2';
import Registration from './pages/registration-v2';
import { ResetPasswordPage } from './pages/password';
import Homepage from './pages/homepage-v2';
import AgencyPage from './pages/homepage-v2/AgencyPage';
import LearnMorePage from './pages/homepage-v2/LearnMorePage';
import SuccessStoryPage from './pages/homepage-v2/SuccessStoryPage';

const HomepageRoutes = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.root} component={Homepage} />
      <Route exact path={ROUTES.agency} component={AgencyPage} />
      <Route exact path={ROUTES.successStory} component={SuccessStoryPage} />
      <Route path={ROUTES.learnMore} component={LearnMorePage} />
      <RouteView path={ROUTES.login} component={LoginWindow} />
      <RouteView path={ROUTES.registration} component={Registration} />
      <RouteView path={ROUTES.resetPassword} component={ResetPasswordPage} />
    </Switch>
  );
};

export default HomepageRoutes;
