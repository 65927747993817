import axios from 'axios/index';
import API from '@sm/api';
import { sendError } from '@utils';
import { userTrackingReset } from '@utils/userTracking';
import { clearSessionStorage, getTrackingId, setToken } from '@sm/storage';
import { SET_NETWORK_ACTIVITY, setNotificationMsgAction, setSuccessMsgAction } from './index';

export const SET_USERNAME = 'SET_USERNAME';
export const SET_TOKEN = 'SET_TOKEN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_TOKEN_REFRESH_EXPIRED = 'SET_TOKEN_REFRESH_EXPIRED';

export const setTokenAction = token => {
  setToken(token);
  return { type: SET_TOKEN, token };
};

export const setTokenRefreshExpiredAction = payload => ({
  type: SET_TOKEN_REFRESH_EXPIRED,
  payload
});

export const setUsernameAction = username => ({ type: SET_USERNAME, username });

export const userLogoutAction = () => {
  clearSessionStorage();
  userTrackingReset();
  return { type: USER_LOGOUT };
};

export const loginAction = (_username, password, handleLogIn, handleFail) => {
  return async dispatch => {
    const username = _username.toLowerCase();
    dispatch(setUsernameAction(username));
    dispatch(setNotificationMsgAction(''));
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
    axios
      .post(API.login, { username, password }, { timeout: 10000 })
      .then(response => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        if (response.data.token) {
          dispatch(setTokenAction(response.data.token));
          dispatch(setTokenRefreshExpiredAction(false));
          if (handleLogIn) {
            handleLogIn();
          } else if (handleFail) {
            handleFail();
          }
        }
      })
      .catch(error => {
        console.log('loginAction FAIL');
        console.log(error);
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        let msg = error.response ? error.response.data?.non_field_errors?.[0] : error.data;
        if (!msg) msg = 'Login attempt failed. Please try again.';
        dispatch(setNotificationMsgAction(msg));
      });
  };
};

export const logoutAction = () => dispatch => dispatch(userLogoutAction());

export const signupAction = (username, email, password, captchaToken, promo, handleLogIn, handleLoginFailed) => {
  return async dispatch => {
    dispatch(setUsernameAction(username));
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });

    const trackingId = getTrackingId();
    axios
      .post(
        API.signup,
        { username, email, password, captchaToken, promo, trackingId },
        { method: 'post', timeout: 10000 }
      )
      .then(() => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(loginAction(email, password, handleLogIn));
        if (window.dataLayer) {
          try {
            window.dataLayer.push({ event: 'conversion_event_signup', email });
          } catch (e) {
            sendError(e);
          }
        }
      })
      .catch(response => {
        let msg = response.response ? response.response.data : response.response;
        if (!msg || typeof msg !== 'string') msg = 'Sign up attempt failed. Please try again.';
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(setNotificationMsgAction(String(msg)));
        handleLoginFailed()
      });
  };
};

export const addSellerAccountAction = (
  title,
  region,
  mainMarketplace,
  sellerID,
  mwsAuthToken,
  handleLogIn,
  handleFail,
  isOnboarding
) => {
  return async dispatch => {
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
    const requestData = {
      region,
      mainMarketplace,
      seller_id: sellerID ? sellerID.trim() : sellerID,
      name: title
    };
    if (mwsAuthToken) {
      requestData.auth_token = mwsAuthToken.trim();
    }
    axios
      .post(API.addSellerAccount, requestData)
      .then(() => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        if (!isOnboarding) {
          dispatch(setSuccessMsgAction('Added seller account'));
        }
        handleLogIn();
      })
      .catch(response => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(
          setNotificationMsgAction(
            (response.response ? response.response.data : response.response) ||
              'Failed to add an account. Please contact support@sellermetrix.com if problem persists.'
          )
        );
        console.log(response);
        handleFail();
      });
  };
};
