import API from '@sm/api';
import mock from '../mock';

const sellerProfileDB = {
  status: 'Active',
  subscription: {
    cc: true
  },
  notConnectedSPA: false,
  not_approved_sender_email: false,
  start: '2019-05-19',
  getStartedChecklist: {
    download_mobile_app: true,
    set_production_cost: true,
    taken_tour: true,
    download_chrome_extension: true,
    email_followup: true
  },
  defaultSettings: {
    marketplace: 3,
    dateRange: 'Last 30 days',
    currency: 'USD'
  },
  sp_api_hash: '32753a270910c8977eaeda27c8c58488803586fd70ec84be8f23e0c1d73e0ca0',
  marketplaces: [0, 1, 3],
  email: 'demo@sellermetrix.com',
  userName: 'demo@sellermetrix.com',
  companyName: 'Forty Five Pte Ltd',
  address: 'Singapore',
  tax_id: null,
  country: null,
  linkedPPC: true,
  pcc_needs_reconnect: false,
  user_hash: 'daf8286da3b9eac6fc7a3833e8c10c86197cc04d32c4f9b4a1a339ae4a3e8a46',
  bookmarks: true,
  beta: false,
  billing: true,
  id: 6168,
  historical_cogs_bulk_upload: false,
  ads_products_spread: false,
  monthly_brand_review: false,
  pnl_dashboard_start: '2019-05-19',
  amazon_traffic_page: true,
  company_residence_country: 'Singapore',
  is_staff: false,
  insights: false
};

const agencyProfileDB = {
  status: 'Active',
  subscription: {
    cc: false
  },
  defaultSettings: {
    marketplace: 7,
    dateRange: 'Today',
    currency: 'EUR'
  },
  email: 'agency@test.it',
  userName: 'iAgency',
  companyName: null,
  address: null,
  country: null,
  agency: true,
  getStartedChecklist: {
    download_mobile_app: false,
    set_production_cost: false,
    taken_tour: false,
    download_chrome_extension: false
  },
  pnl_dashboard_start: '2022-09-01',
  insights: 'end_user' // end_user | admin
};

const accountsDB = {
  A3NW2RP3PUPP94: {
    products: 2,
    marketplaces: [1, 3],
    mainMarketplace: 3,
    title: 'Paula Herrmann North America Seller Account',
    linkedSPA: true,
    linkedPPC: true,
    SCEmailAddress: 'xxxxx@metrixmail.com',
    SCEmailAddressApproved: true,
    sp_api_endpoints_access_denied: null
  }
};

mock.onGet(API.user.profile, { params: { userId: 3 } }).reply(() => {
  return [200, { ...sellerProfileDB, ba: true }];
});

mock.onPost(API.shopify.shop).reply(config => {
  console.log(config);
  sellerProfileDB.status = 'shopify-onboarding';
  return [200];
});

mock.onGet(API.user.profile, { params: { userId: 2 } }).reply(data => {
  console.log(data);
  return [200, agencyProfileDB];
});

mock.onGet(API.user.profile, { params: { userId: 1 } }).reply(() => {
  return [200, sellerProfileDB];
});

mock.onGet(API.user.profile).reply(() => {
  return [200, sellerProfileDB];
});

mock.onGet(API.user.accounts).reply(() => {
  return [200, accountsDB];
});

mock.onPut(API.user.mwsToken).reply(() => {
  sellerProfileDB.accessDenied = false;
  return [200];
});

mock.onPut(API.user.profile).reply(() => {
  sellerProfileDB.avatar = 'http://i.stack.imgur.com/Dj7eP.jpg';
  return [200];
});
