import React from 'react';
import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import PLANS_LIST from '@sm/PlansList';
import { RoundedButton } from '@componentsV2';
import ROUTES from 'seller/routing';
import StarCheckIcon from './img/common/star_checkbox.svg';
import { PRICING_ANCHOR } from './consts';

function HomepagePricing() {
  return (
    <Element
      name={PRICING_ANCHOR}
      className="w-full flex flex-col items-center px-4 xl:px-0 pt-10 pb-4 xl:pb-15 text-gray-darkest xl:max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px"
    >
      <div className="flex flex-col items-center text-center">
        <div className="font_30px xl:font_40px xxl:font_50px font-bold mb-2 xl:mb-5">
          <span>Get access to all features </span>
          <span className="playfair_display italic">regardless </span>
          <span>of your size!</span>
        </div>
        <p className="font-light text-lg xxl:text-xl">
          Try 30 days for free by signing up today. No credit card required. No hidden fees.
        </p>
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-4 w-full mt-4 xl:mt-10 gap-4 xl:gap-6">
        {PLANS_LIST.map(value => {
          return (
            <div
              key={value.title}
              className="flex flex-col items-center justify-between sm-yellow-shadow rounded-xl p-5 xl:p-7.5 bg-white"
            >
              <div className="flex flex-col items-center">
                <img src={value.srcV2} alt="plan" className="w-16" />
                <h3 className="xl:text-xl mt-7.5">{value.title}</h3>
                <div>
                  <span className="font_24px xl:font_35px">${value.price}</span>
                  <span className="text-sm xl:text-base text-gray-dark">/Month</span>
                </div>
                {value.extraDescription && (
                  <div className="text-sm text-center mt-2">{value.extraDescription}</div>
                )}
                <div className="h-px w-full bg-gray mt-3" />
              </div>
              <div className="mt-3 text-sm xl:text-base">
                <div className="flex items-center gap-2">
                  <img src={StarCheckIcon} alt="star-checkbox" />
                  <p>{value.price ? 'Try 30 Days for Free!' : 'FREE! Forever'}</p>
                </div>
                <div className="flex items-start mt-3 gap-2">
                  <img src={StarCheckIcon} alt="star-checkbox" />
                  <p>{value.descriptionV2 || value.description}</p>
                </div>
              </div>
              <Link to={ROUTES.registration} className="w-full">
                <RoundedButton text="Let's Try" className="w-full max-w-full mt-6" />
              </Link>
            </div>
          );
        })}
      </div>
    </Element>
  );
}

export default HomepagePricing;
