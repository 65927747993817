/* eslint-disable global-require */

export const workerLaptopWebImg = {
  src: require('./worker_laptop.png'),
  srcWebp: require('./worker_laptop.webp'),
  srcMobile: require('./worker_laptop_mobile.png'),
  srcMobileWebp: require('./worker_laptop_mobile.webp')
};

export const agencyBannerWebImg = {
  src: require('./agency_banner.png'),
  srcWebp: require('./agency_banner.webp'),
  srcMobile: require('./agency_banner_mobile.png'),
  srcMobileWebp: require('./agency_banner_mobile.webp')
};

export const globalMapWebImg = {
  src: require('./global_map.png'),
  srcWebp: require('./global_map.webp'),
  srcMobile: require('./global_map_mobile.png'),
  srcMobileWebp: require('./global_map_mobile.webp')
};

export const mobilePhoneWebImg = {
  src: require('./mobile_phone.png'),
  srcWebp: require('./mobile_phone.webp'),
  srcMobile: require('./mobile_phone_mobile.png'),
  srcMobileWebp: require('./mobile_phone_mobile.webp')
};

export const googlePlayWebImg = {
  src: require('./google_play.png'),
  srcWebp: require('./google_play.webp')
};

export const appleStoreWebImg = {
  src: require('./apple_store.png'),
  srcWebp: require('./apple_store.webp')
};
