/* eslint-disable camelcase */
import axios from 'axios/index';
import { isBefore } from 'date-fns';
import API, { isIncompleted, isOnboarding } from '@sm/api';
import { axiosCall, configSentry, dateFromStr, isDemoBuild, sendError, setGtmUserId } from '@utils';
import { getAppMenu, getUserMenu } from 'seller/appList';
import { EU_MARKETPLACES, GLOBAL_MK } from '@sm/Flags';
import { getRangeBySnippet } from '@components/date-picker/snippets';
import { getDatePresetByName } from '@components/date-picker/range-list/RangeList';
import { setIntercom } from '@utils/intercom';
import { utIdentify } from '@utils/userTracking';
import { getIsCorsError } from 'seller/store/saga/system';
import { SET_DATE_RANGE, SET_MARKETPLACE } from './selector.actions';
import { SET_NETWORK_ACTIVITY, setNotificationMsgAction, setSuccessMsgAction } from './index';

import { setTokenAction, setUsernameAction } from './login.actions';
import { configureApps } from './app.actions';
import { refreshTokenAction } from '../saga/login';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_ACCOUNTS = 'SET_USER_ACCOUNTS';
export const SET_DEFAULT_SETTINGS = 'SET_DEFAULT_SETTINGS';
export const SET_USER_LAST_30D_BILLING_PLAN = 'SET_USER_LAST_30D_BILLING_PLAN';

function setUserProfile(profile) {
  return { type: SET_USER_PROFILE, profile };
}

export const setUserID = userId => ({ type: SET_USER_ID, userId: +userId });

const getLifetimeLimitedDateRange = (range, lifetime) => {
  const result = { ...range };
  if (isBefore(range.from, lifetime)) result.from = lifetime;
  return result;
};

const doLoadUserProfile = (
  dispatch,
  getState,
  userId,
  handleAuthorized,
  handleUnauthorized,
  agencyDateRange,
  openedFromAgency
) => {
  const { login } = getState();
  if (!login.token && !isDemoBuild()) return handleUnauthorized();
  return axios
    .get(API.user.profile)
    .then(res => {
      console.log(res.data);
      const { email, companyName, userName, user_hash, id } = res.data;
      configSentry({ userId, id });
      setGtmUserId(id);
      utIdentify(id);
      const profile = {
        ...res.data,
        useVAT: !!EU_MARKETPLACES.find(value => res.data?.marketplaces?.includes(value.id))
      };
      const defaultLifetime = localStorage.getItem('default_lifetime');
      if (!profile.start || defaultLifetime) {
        profile.start = '1994-07-05';
      }
      if (!profile.marketplaces?.length) {
        profile.marketplaces = [GLOBAL_MK];
        profile.no_marketplaces = true;
      }
      profile.start = dateFromStr(profile.start);

      dispatch(
        configureApps(getAppMenu(profile), getUserMenu(profile), !profile.agency, !profile.agency)
      );

      if (!isOnboarding(profile) && !isIncompleted(profile)) {
        const getMarketplaceId = () => {
          if (openedFromAgency) {
            return profile.marketplaces?.length > 1 ? GLOBAL_MK : profile.marketplaces?.[0];
          }
          return profile.defaultSettings
            ? profile.defaultSettings.marketplace
            : profile.marketplaces?.[0];
        };
        const marketplaceID = getMarketplaceId();
        if (marketplaceID || marketplaceID === GLOBAL_MK)
          dispatch({ type: SET_MARKETPLACE, marketplaceID });

        if (agencyDateRange) {
          dispatch({
            type: SET_DATE_RANGE,
            dateRange: getLifetimeLimitedDateRange(agencyDateRange, profile.start)
          });
        } else {
          const rangePreset = getDatePresetByName(profile.defaultSettings.dateRange);
          const range = getRangeBySnippet(rangePreset, new Date(), profile.start);
          dispatch({ type: SET_DATE_RANGE, dateRange: range });
        }
      }
      const beta = sessionStorage.getItem('beta');
      if (beta !== null) profile.beta = beta === 'true';
      dispatch(setUserProfile(profile));
      setIntercom(email, companyName, userName, user_hash, id);
      if (handleAuthorized) {
        handleAuthorized(profile);
      }
      dispatch(refreshTokenAction());
    })
    .catch(pullProfileError => {
      console.log('pullProfileError', pullProfileError);
      const serverUnavailable = getIsCorsError(pullProfileError);
      if (handleUnauthorized) {
        handleUnauthorized(serverUnavailable);
      }
      if (!serverUnavailable) {
        dispatch(setTokenAction(null));
        dispatch(setUsernameAction(null));
        sendError(pullProfileError);
      }
    });
};

export const loadUserProfile = (
  userId,
  handleAuthorized,
  handleUnauthorized,
  agencyDateRange,
  openedFromAgency
) => {
  return (dispatch, getState) => {
    if (userId) dispatch(setUserID(userId));
    return doLoadUserProfile(
      dispatch,
      getState,
      userId,
      handleAuthorized,
      handleUnauthorized,
      agencyDateRange,
      openedFromAgency
    );
  };
};

export const loadUserAccounts = (handleSuccess = null) => {
  return (dispatch, getState) => {
    const { userId } = getState().user;
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });

    axios
      .get(API.user.accounts, { params: { userId } })
      .then(res => {
        console.log(`loadUserAccounts at ${API.user.accounts}`);
        console.log(res.data);
        dispatch({ type: SET_USER_ACCOUNTS, accounts: res.data });
        if (handleSuccess) {
          handleSuccess();
        }
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
      })
      .catch(reason => {
        console.log(reason);
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
      });
  };
};

export const renameUserAccount = (name, sellerId, handleSuccess = null) => {
  return (dispatch, getState) => {
    const { userId } = getState().user;
    const failedAction = () => {
      dispatch(setNotificationMsgAction('Account rename failed'));
      if (handleSuccess) {
        handleSuccess(false);
      }
    };
    axios
      .patch(
        API.user.accounts,
        {
          [sellerId]: {
            title: name
          }
        },
        { params: { userId } }
      )
      .then(res => {
        if (res.status === 200) {
          dispatch(setSuccessMsgAction('Account was renamed successfully'));
          dispatch(loadUserAccounts());
          if (handleSuccess) {
            handleSuccess(true);
          }
        } else {
          failedAction();
        }
      })
      .catch(reason => {
        console.log(reason);
        failedAction();
      });
  };
};

export const setSCEmailAddressApproved = (
  SCEmailAddressApproved,
  sellerId,
  handleSuccess = null
) => {
  return (dispatch, getState) => {
    const { userId } = getState().user;
    const failedAction = () => {
      dispatch(setNotificationMsgAction('Approved status save failed'));
      if (handleSuccess) {
        handleSuccess(false);
      }
    };
    axios
      .patch(
        API.user.accounts,
        {
          [sellerId]: {
            SCEmailAddressApproved
          }
        },
        { params: { userId } }
      )
      .then(res => {
        if (res.status === 200) {
          dispatch(setSuccessMsgAction('Approved status saved successfully'));
          dispatch(loadUserAccounts());
          dispatch(loadUserProfile());
          if (handleSuccess) {
            handleSuccess(true);
          }
        } else {
          failedAction();
        }
      })
      .catch(reason => {
        console.log(reason);
        failedAction();
      });
  };
};

export const changePasswordAction = (oldPassword, newPassword) => {
  return dispatch => {
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });

    axios
      .put(API.user.password, { old_password: oldPassword, new_password: newPassword })
      .then(res => {
        console.log(`changed password`);
        console.log(res.data);
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(setSuccessMsgAction('Password changed'));
      })
      .catch(response => {
        console.log(response);
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(setNotificationMsgAction(String(response.response.data)));
      });
  };
};

export const updateMwsToken = (seller_id, mws_token, callback) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
    const { userId } = getState().user;

    axios
      .put(API.user.mwsToken, { userId, seller_id, mws_token })
      .then(() => {
        dispatch(
          loadUserAccounts(() => {
            dispatch(setSuccessMsgAction('Token Successfully Updated'));
          })
        );
        if (callback) callback();
      })
      .catch(response => {
        console.log(response);
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(
          setNotificationMsgAction(
            String(response.response ? response.response.data : response.response)
          )
        );
      });
  };
};

export const updateProfileAction = (profileChanges, handleSuccess, quietly) => {
  return async (dispatch, getState) => {
    try {
      if (!quietly) dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
      const { userId } = getState().user;
      await axiosCall.patch(API.user.profile, profileChanges);
      await doLoadUserProfile(dispatch, getState, userId);
      if (handleSuccess) handleSuccess();
      if (!quietly) dispatch(setSuccessMsgAction('Successfully updated'));
    } catch (e) {
      console.log(e);
      if (!quietly) dispatch(setNotificationMsgAction('Updating failed'));
    } finally {
      dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
    }
  };
};

export const setUserStatusAction = status => {
  return async (dispatch, getState) => {
    const { profile } = getState().user;
    const newProfile = { ...profile, status };
    dispatch({ type: SET_USER_PROFILE, profile: newProfile });
  };
};

export const getUserLast30dPlan = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: true });
    const { userId } = getState().user;

    axios
      .get(API.billing.last_30d_plan, { params: { userId } })
      .then(res => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch({ type: SET_USER_LAST_30D_BILLING_PLAN, data: res.data });
      })
      .catch(reason => {
        dispatch({ type: SET_NETWORK_ACTIVITY, isFetching: false });
        dispatch(
          setNotificationMsgAction(
            `Can't load current plan${reason.response}` ? reason.response.data : reason.response
          )
        );
        console.log(reason);
      });
  };
};
