import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxV1 from '@components/check-box/index';

const CheckBox = props => {
  return (
    <CheckBoxV1
      classNameCheckBox="rounded-sm rounded h-5 w-5 md:w-4 md:h-4"
      bgColorClass="bg-green-emerald"
      borderColorClass="focus:border-green-emerald"
      {...props}
    />
  );
};

CheckBox.propTypes = {
  label: PropTypes.node,
  classNameContainer: PropTypes.string,
  onCheckHandler: PropTypes.func,
  value: PropTypes.bool.isRequired,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool
};

CheckBox.defaultProps = {
  label: null,
  classNameContainer: '',
  onCheckHandler: () => {},
  onKeyPress: null,
  disabled: false
};

export default CheckBox;
