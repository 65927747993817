export const APPSTORE_LINK = 'https://apps.apple.com/sg/app/seller-metrix/id1435738191';
export const PLAYMARKET_LINK = 'https://play.google.com/store/apps/details?id=com.sellermetrix';
export const CHROME_EXT_LINK =
  'https://chrome.google.com/webstore/detail/seller-metrix/onhmpeijafobjmdfjlgjocnopaepmodl';
export const COMPANY_ADDRESS = 'Seller Metrix, 51 Goldhill Plaza, #07-10/11, 308900 Singapore';
export const FACEBOOK_LINK = 'https://www.facebook.com/SellerMetrix/';
export const YOUTUBE_LINK = 'https://www.youtube.com/@sellermetrix52';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/sellermetrix/';
export const SUPPORT_LINK = 'https://intercom.help/sellermetrix/en/collections/630363-support';
export const TERMS_AND_CONDITIONS_LINK = 'https://sellermetrix.com/terms-of-service/';
export const PRIVACY_AND_POLICY_LINK = 'https://sellermetrix.com/privacy-policy/';
// FIXME:https://rainforest-life.atlassian.net/browse/SM-5672
export const DEMO_LINK = 'https://sellermetrix-demo.netlify.app/';
