import React from 'react';
import PropTypes from 'prop-types';

const DetailedErrorView = ({ error }) => {
  // Error format:
  // {
  //   [errorType]: [errorLine1, errorLine2, ...],
  //   [errorType]: [errorLine1, errorLine2, ...],
  //   ...
  // }
  return (
    <div
      className="flex flex-col items-center overflow-auto scroll-container pr-2"
      style={{ maxHeight: '25vh' }}
    >
      {Object.keys(error).map((key, index) => {
        return (
          <div className="flex flex-col items-start gap-2 w-full">
            <div className="font-medium">{key}</div>
            <div className="w-full">
              {error[key]?.map(line => {
                return <div className="text-xs w-full">{line}</div>;
              })}
            </div>
            {index < Object.keys(error).length - 1 && (
              <hr className="w-full border-t border-white mb-2" />
            )}
          </div>
        );
      })}
    </div>
  );
};

DetailedErrorView.propTypes = {
  error: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default DetailedErrorView;
