import React from 'react';
import { WebImg } from '@components';
import NewTabReference from '@components/common/NewTabReference';
import { APPSTORE_LINK, PLAYMARKET_LINK } from '@sm/consts';
import { appleStoreWebImg, googlePlayWebImg, mobilePhoneWebImg } from './img/common';

const MobileBlock = () => {
  return (
    <div className="max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px flex items-center flex-col-reverse xl:flex-row gap-6">
      <div className="xl:w-1/2 text-center xl:text-left px-6 xl:px-0">
        <div className="font_30px xl:font_40px xxl:font_60px font-bold xl:mb-5">
          <span>Mobile Analytics </span>
          <span className="playfair_display italic">Made Easy </span>
          <span>Get Our App Now!</span>
        </div>
        <p className="font-light  text-lg xxl:text-xl mt-2 xl:mt-0">
          Enjoy seamless access to SellerMetrix anytime, anywhere. Our mobile app is now available
          for download on Google Play and the App Store.
        </p>
        <div className="flex gap-6 mt-6 justify-center xl:justify-start">
          <NewTabReference href={PLAYMARKET_LINK}>
            <WebImg
              {...googlePlayWebImg}
              className="block"
              imgClassName="sm-h-52px xl:sm-h-39px"
              alt="play-market"
            />
          </NewTabReference>
          <NewTabReference href={APPSTORE_LINK}>
            <WebImg
              {...appleStoreWebImg}
              className="md:mr-0 block"
              imgClassName="sm-h-52px xl:sm-h-39px"
              alt="app-store"
            />
          </NewTabReference>
        </div>
      </div>
      <div className="w-full mx-6 xl:w-1/2 flex justify-center flex-grow py-6">
        <WebImg
          {...mobilePhoneWebImg}
          alt="mobile-phone"
          imgClassName="sm-h-256px xl:sm-h-480px xxl:sm-h-540px"
        />
      </div>
    </div>
  );
};

export default MobileBlock;
