const ROUTES = {
  // Root
  root: '/',
  agency: '/agency',
  learnMore: '/learn-more',
  successStory: '/success-story',
  demo: '/demo',

  // App
  home: '/app',
  profile: {
    general: '/profile',
    security: '/profile/security',
    settings: '/profile/settings',
    extension: '/profile/extension'
  },
  billing: '/billing/',
  accounts: '/accounts/',
  plans: '/plans',
  maintenance: '/maintenance',

  // Sign up flow.
  welcome: '/welcome/',
  select_region: '/select-region/',

  // Auth.
  login: '/login',
  registration: '/signup',
  setupPassword: '/forgot-password',
  resetPassword: '/reset-password',
  onboarding: '/onboarding/',

  // Pages 404.
  notFound: '/404',
  notFoundUnauthorized: '/oops-page-not-found',

  // Apps.
  orders: '/orders',
  products: '/products',
  vat: '/vat/',
  refunds: '/refunds',
  users: '/clients',
  inventory: '/inventory',

  // Email tool
  email_tool: {
    root: '/email-follow-up',
    dashboard: '/email-follow-up/dashboard',
    newCampaign: '/email-follow-up/new-campaign',
    campaign: '/email-follow-up/campaign',
    newMessage: '/email-follow-up/new-message'
  },
  reports: {
    root: '/reports',
    create: '/reports/new/',
    reportDetails: '/reports/'
  },
  expenses: {
    root: '/expenses',
    new: '/expenses/new',
    edit: '/expenses/edit'
  },
  shopify: {
    sign_up: '/shopify/sign-up',
    onboarding: '/shopify/onboarding'
  },
  advertising: {
    root: '/advertising'
  },
  amazonTraffic: {
    root: '/amazon-traffic'
  },
  monthlyBrandReview: {
    root: '/monthly-brand-review'
  },
  tools: {
    convert_pnl: '/tools/convert-pnl'
  },
  pnlDashboard: {
    root: '/profit-and-loss'
  },
  insights: {
    root: '/insights'
  }
};

const ALWAYS_ALLOWED_ROUTES = [
  ROUTES.welcome,
  ROUTES.onboarding,
  ROUTES.select_region,
  ROUTES.accounts,
  ROUTES.profile.security,
  ROUTES.profile.settings,
  ROUTES.profile.extension,
  ROUTES.profile.general,
  ROUTES.setupPassword,
  ROUTES.resetPassword,
  ROUTES.notFound,
  ROUTES.notFoundUnauthorized,
  ROUTES.maintenance,
  ROUTES.plans,
  ROUTES.billing,
  ROUTES.home,
  ROUTES.agency,
  ROUTES.learnMore,
  ROUTES.tools.convert_pnl,
  ROUTES.pnlDashboard.root
];

export const AGENCY_ALLOWED_ROUTES = [...ALWAYS_ALLOWED_ROUTES, ROUTES.users, ROUTES.insights.root];

export const REGULAR_USER_ALLOWED_ROUTES = [
  ...ALWAYS_ALLOWED_ROUTES,
  ROUTES.inventory,
  ROUTES.email_tool.root,
  ROUTES.refunds,
  ROUTES.products,
  ROUTES.orders,
  ROUTES.vat,
  ROUTES.reports.root,
  ROUTES.expenses.root,
  ROUTES.shopify.sign_up,
  ROUTES.shopify.onboarding,
  ROUTES.advertising.root,
  ROUTES.monthlyBrandReview.root,
  ROUTES.amazonTraffic.root,
  ROUTES.email_tool.dashboard,
  ROUTES.insights.root
];

export default ROUTES;
