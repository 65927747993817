import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FontButton, MultiLineTruncate, WebImg } from '@components';
import { ImageObserver } from 'seller/containers/observer';

const LearnMoreCard = ({ image, title, onClick }) => {
  const imageClasses = 'h-full w-full  rounded-xl flex justify-center object-cover';
  const getImage = () => {
    if (typeof image === 'string') {
      return <img src={image} alt={title} className={imageClasses} />;
    }
    return (
      <WebImg
        className={imageClasses}
        alt="feature"
        {...image}
        imgClassName="h-full w-full rounded-xl object-cover"
      />
    );
  };

  return (
    <Box
      className="flex flex-col p-5 bg-white rounded-2xl sm-yellow-shadow w-full flex-grow cursor-pointer"
      onClick={onClick}
    >
      <div className="w-full relative" style={{ aspectRatio: '1/1' }}>
        <ImageObserver className="h-full w-full">{getImage()}</ImageObserver>
      </div>
      <div className="flex flex-col items-center mt-4 max-w-full xl:text-lg font-bold leading-8">
        <MultiLineTruncate text={title} lines={2} className="w-full" />
      </div>
      <FontButton className="px-5 py-2 mt-4 ml-auto font-medium text-green-emerald rounded-xl">
        Learn More
      </FontButton>
    </Box>
  );
};

LearnMoreCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default LearnMoreCard;
