import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Sidebar } from '@components';
import ProductsNoResults from '@components/products/products-search-results/ProductsNoResults';
import LoadingSpinner from '@components/common/LoadingSpinner';
import ProductsSearchResult from '@components/products/products-search-results/ProductsSearchResult';

const SearchWidget = props => {
  const {
    data,
    selectedData,
    loading,
    keyword,
    open,
    onCommitSelection,
    onCancel,
    commitImmediately,
    nonSidebarMode,
    maxProductsCount,
    onMaxProductCountReached
  } = props;
  const [nonCommittedSelection, setNonCommittedSelection] = useState([...(selectedData || [])]);
  const onApplyChanges = () => {
    onCommitSelection([...nonCommittedSelection]);
    setNonCommittedSelection([]);
  };

  const updateProductsSearchResultList = (action, value) => {
    const newSelected = [...nonCommittedSelection];
    if (!action) {
      const deletedIndex = newSelected.findIndex(
        item => item.id === value.id && item.type === value.type
      );
      if (deletedIndex > -1) newSelected.splice(deletedIndex, 1);
      setNonCommittedSelection(newSelected);
    } else {
      if (maxProductsCount) {
        const totalSelectedProductsCount =
          newSelected.reduce((acc, item) => acc + (item.productCount || 1), 0) +
          (value.productCount || 1);
        if (totalSelectedProductsCount > maxProductsCount) {
          onMaxProductCountReached();
          return;
        }
      }
      newSelected.push(value);
      setNonCommittedSelection(newSelected);
    }
    if (commitImmediately) onCommitSelection(newSelected);
  };

  const containsAnyProduct =
    (data.brands && data.brands.length) ||
    (data.parent_products && data.parent_products.length) ||
    (data.products && data.products.length);

  let searchContent;
  if (loading) {
    searchContent = <LoadingSpinner className="bg-violet-white" />;
  } else if (!containsAnyProduct) {
    searchContent = <ProductsNoResults searchText={keyword} />;
  } else {
    searchContent = (
      <ProductsSearchResult
        data={data}
        selected={nonCommittedSelection}
        onUpdate={updateProductsSearchResultList}
        onAccept={onApplyChanges}
        onCancel={onCancel}
        hideCancelConfirm={commitImmediately}
      />
    );
  }
  if (nonSidebarMode) return searchContent;
  return (
    <>
      <Sidebar isSearchDialogOpened={open} handleClose={onApplyChanges}>
        {searchContent}
      </Sidebar>
    </>
  );
};

SearchWidget.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  selectedData: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
  keyword: PropTypes.string,
  open: PropTypes.bool,
  commitImmediately: PropTypes.bool,
  onCommitSelection: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  nonSidebarMode: PropTypes.bool,
  maxProductsCount: PropTypes.number,
  onMaxProductCountReached: PropTypes.func
};

SearchWidget.defaultProps = {
  loading: false,
  keyword: '',
  open: false,
  commitImmediately: false,
  onCommitSelection: () => {},
  nonSidebarMode: false,
  maxProductsCount: null,
  onMaxProductCountReached: () => {}
};

export default SearchWidget;
