import React from 'react';
import PropTypes from 'prop-types';
import HomepageHeader from './HomepageHeader';
import { HEADER_HEIGHT, HOMEPAGE_SCROLL_CONTAINER_ID } from './consts';

const HeaderLayout = ({ children, scrollContainerRef }) => {
  return (
    <div className="flex justify-center h-full">
      <HomepageHeader />
      <div
        id={HOMEPAGE_SCROLL_CONTAINER_ID}
        ref={scrollContainerRef}
        style={{ marginTop: HEADER_HEIGHT, height: `calc(100vh - ${HEADER_HEIGHT}` }}
        className="relative w-full flex-grow overflow-auto"
      >
        {children}
      </div>
    </div>
  );
};

HeaderLayout.propTypes = {
  children: PropTypes.node.isRequired,
  scrollContainerRef: PropTypes.instanceOf(Object)
};

HeaderLayout.defaultProps = {
  scrollContainerRef: null
};

export default HeaderLayout;
