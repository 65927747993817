import React from 'react';
import { withRouter } from 'react-router-dom';
import { WebImg } from '@components';
import { isIntercomEnabled } from '@utils/intercom';
import { RoundedButton } from '@componentsV2';
import { ImageObserver } from 'seller/containers/observer';
import HomepageFooter from './HomepageFooter';
import UnderHeaderBlock from './UnderHeaderBlock';
import { getCarouselImg } from './img/carousel';
import HeaderLayout from './HeaderLayout';

function AgencyPage() {
  const title = (
    <>
      <span>Simplify Your </span>
      <span className="text-grad-1 playfair_display italic">Amazon Agency</span>
      <span> Workflow</span>
    </>
  );

  return (
    <HeaderLayout>
      <ImageObserver
        className="w-full flex flex-col items-center text-center xl:text-left"
        loadingClassname="overflow-hidden absolute w-screen h-screen"
      >
        <UnderHeaderBlock
          title={title}
          description="SellerMetrix streamlines your workflow with centralized, real-time analytics,
      making it easy to manage multiple clients and focus on growth."
          getRegistrationButton={() => (
            <RoundedButton
              text="Inquire Now"
              onClick={() => {
                if (!isIntercomEnabled()) return;
                Intercom(
                  'showNewMessage',
                  'Hi SellerMetrix Team,\nI would like to register as an agency to have the opportunity to manage my clients within the SellerMetrix app.\nThank you for your help!'
                );
              }}
            />
          )}
        />
        <div className="flex items-center mb-3 px-6 xl:px-0">
          <div className="font-bold font_35px xl:font_50px">
            <span>Unify </span>
            <span className="playfair_display italic">Client Insights </span>
            <span>with SellerMetrix</span>
          </div>
        </div>
        <div
          className="w-full flex justify-center pb-4"
          style={{
            background:
              'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 12%, rgba(249,173,25,0.5) 100%)'
          }}
        >
          <div className="flex flex-col items-center w-full max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px px-6 xl:px-0">
            <WebImg
              className="w-full flex items-center justify-center py-6"
              imgClassName="sm-h-224px xl:sm-h-480px rounded-2xl"
              alt="feature"
              {...getCarouselImg('agency')}
            />
            <div className="flex flex-col justify-start items-center mb-6 xl:mb-10">
              <div className="font_24px xl:font_35px font-bold">
                Track Your Client’s Amazon Sales & Profit - In Real Time
              </div>
              <p className="xl:text-xl font-light mt-3">
                Check key financial metrics like Sales, Profit, PPC Spend, Amazon Fees, ROI, and
                Refunds in real time, all consolidated in one place for complete clarity.
              </p>
            </div>
          </div>
        </div>
        <HomepageFooter />
      </ImageObserver>
    </HeaderLayout>
  );
}

AgencyPage.propTypes = {};

export default withRouter(AgencyPage);
