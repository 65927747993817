import React from 'react';
import { WebImg } from '@components';
import { globalMapWebImg } from './img/common';

const WorldwideBlock = () => {
  return (
    <div
      className="w-full flex flex-col items-center pb-4"
      style={{
        background:
          'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(249,173,25,0.3) 50%, rgba(255,255,255,1) 100%)'
      }}
    >
      <div className="w-full flex justify-center px-4 xl:px-0 py-5 xl:py-10 text-gray-darkest xl:max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px">
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center text-center">
            <div className="font-bold font_30px xl:font_40px xxl:font_50px mb-2 xl:mb-5">
              <span>Your </span>
              <span className="playfair_display italic">world wide </span>
              <span>Amazon sales data in one place</span>
            </div>
            <p className=" text-lg xxl:text-xl">Use SellerMetrix with any Amazon marketplace.</p>
          </div>
        </div>
      </div>
      <WebImg
        {...globalMapWebImg}
        className="w-full mt-4 xl:mt-24 xl:px-6 max_w_1440p xxxl:max_w_1596px"
        alt="global-map"
      />
    </div>
  );
};

export default WorldwideBlock;
