import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';

function RoundedButton(props) {
  const {
    text,
    className,
    onClick,
    active,
    outlined,
    textColorClass,
    bgColorClass,
    borderColorClass,
    baseClasses,
    wrapperClasses
  } = props;
  return (
    <Box border={0} className={wrapperClasses}>
      <Button
        variant="outlined"
        border={1}
        className={classNames(
          'normal-case hover:shadow-lg',
          className,
          borderColorClass,
          baseClasses,
          {
            'text-white': !outlined,
            'bg-white ': outlined,
            [textColorClass]: outlined,
            [bgColorClass]: !outlined,
            'opacity-50': !active
          }
        )}
        onClick={onClick}
        disabled={!active}
      >
        {text}
      </Button>
    </Box>
  );
}

RoundedButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  outlined: PropTypes.bool,
  textColorClass: PropTypes.string,
  bgColorClass: PropTypes.string,
  borderColorClass: PropTypes.string,
  baseClasses: PropTypes.string,
  wrapperClasses: PropTypes.string
};

RoundedButton.defaultProps = {
  className: '',
  textColorClass: 'text-green-emerald',
  bgColorClass: 'bg-green-emerald',
  borderColorClass: 'border-green-emerald',
  baseClasses: 'font-bold sm:text-lg px-3 sm:px-6 py-2 sm:py-3 rounded-xl',
  wrapperClasses: '',
  onClick: () => {},
  active: true,
  outlined: false
};

export default RoundedButton;
