import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@material-ui/core';

const getItemAnchorId = value => `tab-${value}`;

const TabSwitcher = ({ options, activeTab, changeActiveTab, className }) => {
  const containerId = 'tabs-scroll-container';
  useEffect(() => {
    // Scroll to active tab if it is not visible in the container
    const container = document.getElementById(containerId);
    const activeTabElement = container.querySelector(`#${getItemAnchorId(activeTab)}`);
    if (!container || !activeTabElement) return;
    const containerRect = container.getBoundingClientRect();
    const activeTabRect = activeTabElement.getBoundingClientRect();
    if (activeTabRect.left < containerRect.left) {
      const scrollOffset = activeTabElement.offsetLeft - containerRect.left;
      container.scrollTo({ left: scrollOffset, behavior: 'smooth' });
    }
    if (activeTabRect.right > containerRect.right) {
      const scrollOffset =
        activeTabElement.offsetLeft -
        containerRect.left -
        containerRect.width +
        activeTabRect.width;
      container.scrollTo({ left: scrollOffset, behavior: 'smooth' });
    }
  }, [activeTab]);
  return (
    <div
      id={containerId}
      className={classNames(
        'max-w-full flex overflow-auto gap-2 invisible-scroll-container',
        className
      )}
    >
      {options.map(option => {
        const isOptionActive = activeTab === option.value;
        return (
          <Box
            id={getItemAnchorId(option.value)}
            key={option.text}
            onClick={() => changeActiveTab(option.value)}
            className={classNames('px-6 py-3 rounded-xl cursor-pointer flex-shrink-0', {
              'text-white bg-yellow font-bold': isOptionActive,
              'text-gray-darkest font-light': !isOptionActive
            })}
          >
            <div>{option.text}</div>
          </Box>
        );
      })}
    </div>
  );
};

TabSwitcher.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]).isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  className: PropTypes.string
};

TabSwitcher.defaultProps = {
  className: ''
};
export default TabSwitcher;
