import React from 'react';
import { IconButton } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CrossIcon } from '@components';
import { RoundedButton, Sidebar } from '@componentsV2';
import ROUTES from 'seller/routing';
import Colors from 'styles/colors';
import { getNavigationLinks } from './HomepageHeader';

const SidebarNavigation = ({ open, setOpen, location }) => {
  if (!open) return null;
  return (
    <div className="absolute h-screen w-screen top-0">
      <Sidebar isSearchDialogOpened={open} handleClose={() => setOpen(false)} styleV2 size="75%">
        <div className="flex flex-col items-start justify-between bg-white h-full p-5 pb-8 mb-8">
          <IconButton onClick={() => setOpen(false)} className="self-end">
            <CrossIcon fill={Colors.gray.dark} />
          </IconButton>
          <div className="flex flex-col items-start gap-5">
            {getNavigationLinks('text-lg', location, () => {
              setOpen(false);
            })}
          </div>
          <div className="flex flex-col gap-4 items-center mt-auto">
            <div className="flex">
              <Link to={ROUTES.login}>
                <RoundedButton
                  text="Sign in"
                  outlined
                  className="mr-3"
                  baseClasses="font-bold text-lg px-6 py-3 rounded-xl"
                />
              </Link>
              <Link to={ROUTES.registration}>
                <RoundedButton
                  text="Sign up"
                  baseClasses="font-bold text-lg px-6 py-3 rounded-xl"
                />
              </Link>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

SidebarNavigation.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(SidebarNavigation);
