import axios from 'axios';
import {
  call,
  cancelled,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from '@redux-saga/core/effects';
import API, { LONG_REQUEST_TIMEOUT } from '@sm/api';
import { productsSelector, selectorSelector, userSelector } from 'seller/store/reducers';
import {
  dateToStr,
  getProductIdentity,
  isSuccessStatus,
  PRODUCT_API_FIELDS,
  HTTP_409_CONFLICT,
  getCancelToken,
  axiosCall
} from '@utils';
import { prepareProductList } from 'seller/utils/convertRawApiData';
import DetailedErrorView from '@components/notifications/DetailedErrorView';
import { failHandler } from '../utils';
import {
  BULK_UPDATE_HCOGS,
  DELETE_HCOG_RANGE,
  PULL_PRODUCT_HCOGS,
  PULL_PRODUCTS,
  pullProductsAction,
  SAVE_HCOG_RANGE,
  SET_PRODUCT_STATUS,
  setBulkUploadingAction,
  setProductAction,
  setProductsAction,
  startProductsLoadingAction,
  UPDATE_PRODUCTS
} from './action';
import {
  setBulkHcogsWarningShowAction,
  setNotificationMsgAction,
  setSuccessMsgAction,
  setWarningMsgAction
} from '../../actions';

const COGS_API_FIELDS = [
  'unit_cost',
  'unit_shipping_cost',
  'fbm_shipping_cost',
  'fbm_return_cost',
  'currency',
  'inventory'
];
const POST_PRODUCT_API_FIELDS = [...PRODUCT_API_FIELDS, ...COGS_API_FIELDS];
const ERROR_409_NOTIFICATION_TEXT =
  'Orders still updating. Please wait a few minutes and try again';

const getProductUpdatedNotificationText = isBulkUpload =>
  `Product${
    isBulkUpload ? 's' : ''
  } successfully updated. It may take a few minutes before the change is reflected in the dashboard`;

export function* pullProductsSaga() {
  yield put(startProductsLoadingAction());
  const { cancelToken, cancelRequest } = getCancelToken();
  try {
    const { marketplaceID, productStatus: status } = yield select(selectorSelector);
    const response = yield axios.get(API.products.list, {
      params: { marketplaceID, status, cancelToken },
      timeout: LONG_REQUEST_TIMEOUT
    });
    if (response.data.error) {
      yield put(setNotificationMsgAction('Products loading failed'));
      yield put(setProductsAction([]));
    } else {
      yield put(setProductsAction(prepareProductList(response.data)));
    }
  } catch (e) {
    yield put(setNotificationMsgAction('Products loading failed'));
    yield put(setProductsAction([]));
  } finally {
    if (yield cancelled()) cancelRequest();
  }
}

const prepareProductsData = products =>
  products.map(product =>
    POST_PRODUCT_API_FIELDS.reduce((acc, key) => {
      if (product[key]) acc[key] = product[key];
      return acc;
    }, {})
  );

function* updateProductsErrorHandler({ error, errorData, callback }) {
  if (error.response?.status === HTTP_409_CONFLICT) {
    yield put(setWarningMsgAction(ERROR_409_NOTIFICATION_TEXT));
  } else {
    const message = error.response?.data?.error?.message || 'Product updating failed';
    yield fork(failHandler, [
      errorData,
      `${message}. Please contact support@sellermetrix.com if problem persists.`,
      error
    ]);
  }
  if (callback) callback(false);
}

export function* updateProductsSaga({ payload }) {
  const { products, isBulkUpload, onRequestFinish, date } = payload;
  const { userId } = yield select(userSelector);
  const { marketplaceID } = yield select(selectorSelector);
  const params = {
    userId,
    products: prepareProductsData(products)
  };
  if (date) {
    params.date = dateToStr(date);
  }
  const { productsList } = yield select(productsSelector);
  if (!isBulkUpload) {
    params.marketplaceID = marketplaceID;
  } else {
    yield put(startProductsLoadingAction());
  }
  try {
    const { status, data } = yield axios.post(API.products.list, params, {
      timeout: LONG_REQUEST_TIMEOUT
    });
    if (isSuccessStatus(status)) {
      onRequestFinish(true);
      if (isBulkUpload) {
        yield put(setBulkUploadingAction());
        if (data?.error) {
          yield put(
            setNotificationMsgAction(DetailedErrorView({ error: data?.error }), 'warning', true)
          );
        } else if (!data?.not_found) {
          yield put(setSuccessMsgAction(getProductUpdatedNotificationText(true)));
        } else {
          yield put(
            setWarningMsgAction(
              `${data.not_found?.length} of ${products.length} products failed to update`,
              false,
              false
            )
          );
        }
      } else {
        yield put(setSuccessMsgAction(getProductUpdatedNotificationText()));
      }
    } else {
      onRequestFinish(false);
      if (isBulkUpload) {
        yield put(setProductsAction(productsList));
        yield put(setBulkUploadingAction());
      }
      yield fork(failHandler, [params, 'Update product(s) failed']);
    }
  } catch (error) {
    yield call(updateProductsErrorHandler, { error, errorData: params, callback: onRequestFinish });
    if (isBulkUpload) yield put(setProductsAction(productsList));
  }
}

export function* bulkUpdateHcogs({ payload }) {
  const { products, date } = payload;
  const { userId } = yield select(userSelector);
  const { productsList } = yield select(productsSelector);
  const params = { userId, products: prepareProductsData(products), date: dateToStr(date) };
  yield put(startProductsLoadingAction());
  yield put(setBulkUploadingAction());
  try {
    const { data } = yield axios.post(API.products.list, params, {
      timeout: LONG_REQUEST_TIMEOUT
    });
    yield put(setSuccessMsgAction(data?.details));
    yield put(setBulkHcogsWarningShowAction(null));
    yield put(pullProductsAction());
  } catch (error) {
    if (error.response?.status === HTTP_409_CONFLICT) {
      yield put(setWarningMsgAction(ERROR_409_NOTIFICATION_TEXT));
    } else {
      yield put(
        setBulkHcogsWarningShowAction(
          error?.response?.data?.error || { message: 'Update product(s) failed' }
        )
      );
    }
    yield put(setProductsAction(productsList));
  } finally {
    yield put(setBulkUploadingAction());
  }
}

const prepareHistory = (data, lifeTime, productIdentity) => {
  if (!data) return [];
  return data.map(item => ({
    ...item,
    productIdentity,
    range: { ...item.range, from: item.range?.from || dateToStr(lifeTime) }
  }));
};

export function* pullProductHCogsSaga({ payload: productData }) {
  const {
    userId,
    profile: { start }
  } = yield select(userSelector);
  const productIdentity = getProductIdentity(productData);
  const params = { params: productIdentity };
  if (userId) params.params.userId = userId;
  try {
    const { data } = yield axiosCall.get(API.products.hCog, params);
    const productWithHcog = {
      ...productData,
      history: prepareHistory(data, start, productIdentity)
    };
    yield put(setProductAction(productWithHcog));
  } catch (e) {
    yield fork(failHandler, [params, 'Load product historical CoGS failed', e]);
  }
}

export function* deleteHcogRangeSaga({ id, callback }) {
  try {
    yield axiosCall.delete(`${API.products.hCog}/${id}/`);
    callback(true);
    yield put(setSuccessMsgAction(getProductUpdatedNotificationText()));
  } catch (error) {
    yield call(updateProductsErrorHandler, { error, errorData: id, callback });
  }
}

const prepareRangeToApi = (range, lifeTime) => ({
  ...range,
  from: range.from === dateToStr(lifeTime) ? null : range.from
});

const getCogs = rangeData =>
  COGS_API_FIELDS.reduce((acc, key) => ({ ...acc, [key]: rangeData[key] || 0 }), {});

const prepareRangeDataToApi = (rangeData, lifeTime) => ({
  ...getCogs(rangeData),
  range: prepareRangeToApi(rangeData.range, lifeTime),
  ...rangeData.productIdentity
});

export function* putHcogRangeSaga({ rangeData, callback }) {
  const { profile } = yield select(userSelector);
  try {
    yield axiosCall.put(
      `${API.products.hCog}/${rangeData.id}/`,
      prepareRangeDataToApi(rangeData, profile.start)
    );
    callback(rangeData.id);
    yield put(setSuccessMsgAction(getProductUpdatedNotificationText()));
  } catch (error) {
    yield call(updateProductsErrorHandler, { error, errorData: rangeData, callback });
  }
}

export function* postHcogRangeSaga({ rangeData, callback }) {
  const { profile } = yield select(userSelector);
  try {
    const { data } = yield axiosCall.post(
      API.products.hCog,
      prepareRangeDataToApi(rangeData, profile.start)
    );
    callback(data);
    yield put(setSuccessMsgAction('Product successfully updated'));
  } catch (error) {
    yield call(updateProductsErrorHandler, { error, errorData: rangeData, callback });
  }
}

export function* saveHcogRangeSaga(action) {
  if (action.rangeData?.id === null) {
    yield call(postHcogRangeSaga, action);
  } else {
    yield call(putHcogRangeSaga, action);
  }
}

export function* productsSagaWatcher() {
  yield takeLatest(PULL_PRODUCTS, pullProductsSaga);
  yield takeLatest(SET_PRODUCT_STATUS, pullProductsSaga);
  yield takeLatest(UPDATE_PRODUCTS, updateProductsSaga);
  yield takeLatest(BULK_UPDATE_HCOGS, bulkUpdateHcogs);
  yield takeEvery(PULL_PRODUCT_HCOGS, pullProductHCogsSaga);
  yield takeEvery(DELETE_HCOG_RANGE, deleteHcogRangeSaga);
  yield takeEvery(SAVE_HCOG_RANGE, saveHcogRangeSaga);
}
