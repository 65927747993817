import StarterImage from './img/plan_starter@2x.png';
import GrowthImage from './img/plan_growth@2x.png';
import ProImage from './img/plan_pro@2x.png';
import EnterpriseImage from './img/plan_enterprise@2x.png';
import StarterPlanV2 from './img/starter_plan_v2.svg';
import GrowthPlanV2 from './img/growth_plan_v2.svg';
import ProPlanV2 from './img/pro_plan_v2.svg';
import EnterprisePlanV2 from './img/enterprise_plan_v2.svg';

const PLANS_LIST = [
  {
    title: 'Starter',
    description: '0 to 100 orders per month',
    descriptionV2: 'Up to 100 Orders per month',
    src: StarterImage,
    srcV2: StarterPlanV2,
    price: 0,
    maxOrders: 100
  },
  {
    title: 'Growth',
    description: '101 to 2500 orders per month',
    percentage: 73,
    src: GrowthImage,
    srcV2: GrowthPlanV2,
    price: 15,
    maxOrders: 2500
  },
  {
    title: 'Pro',
    description: '2501 to 5000 orders per month',
    src: ProImage,
    srcV2: ProPlanV2,
    price: 35,
    maxOrders: 5000
  },
  {
    title: 'Enterprise',
    description: '5001 to 10000 orders per month',
    extraDescription: '+$7 Dollar For Each 1000 Additional Orders',
    src: EnterpriseImage,
    srcV2: EnterprisePlanV2,
    price: 85
  }
];

export default PLANS_LIST;
