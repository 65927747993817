import React from 'react';
import UnderHeaderBlock from './UnderHeaderBlock';

function HomepageMainBlock() {
  const title = (
    <>
      <span>Grow and Manage Your </span>
      <span className="text-grad-1 playfair_display italic">Amazon Business </span>
      <span>- for Agencies and Sellers</span>
    </>
  );
  return (
    <UnderHeaderBlock
      title={title}
      description="Gain Sales Insights, Grow Product Reviews, and Automate Customer Follow-Ups."
    />
  );
}

export default HomepageMainBlock;
