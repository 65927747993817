import React from 'react';
import PropTypes from 'prop-types';
import { TextFieldIcon } from '@componentsV2';

function GenerateInputs(props) {
  const { data } = props;
  return data.map(input => {
    const labelText = input.label || input.type;
    const capitalizedType = labelText.charAt(0).toUpperCase() + labelText.slice(1);
    return (
      <div>
        <div className="text-sm font-bold mb-2">{capitalizedType}</div>
        <TextFieldIcon
          key={capitalizedType}
          type={input.type || 'text'}
          name={input.type}
          label={input.hint}
          fullWidth
          {...input.props}
        />
      </div>
    );
  });
}

GenerateInputs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func.isRequired,
      props: PropTypes.instanceOf(Object).isRequired
    })
  ).isRequired
};

export default GenerateInputs;
