import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Colors from 'styles/colors';
import { BanIcon, CheckIcon, CrossSolidIcon } from '../icons';
import BlackTooltip from '../common/BlackTooltip';

export const DASHBOARD_BANNER_HEIGHT = '4';

const DashboardBannerView = ({ onClose, className, content, startIcon, onAgree }) => {
  return (
    <div
      className={classNames(
        'rounded w-full flex items-center justify-between px-4 sm:px-6 py-2',
        className
      )}
      style={{ minHeight: `${DASHBOARD_BANNER_HEIGHT}rem` }}
    >
      <div className="mr-6 flex-shrink-0">{startIcon}</div>
      {content}
      <div className="ml-auto" />
      {onAgree && (
        <BlackTooltip title="Noted, hide" placement="left" arrow>
          <IconButton className="p-2 mr-2" onClick={onAgree}>
            <CheckIcon fill={Colors.white.default} />
          </IconButton>
        </BlackTooltip>
      )}
      {onClose && (
        <BlackTooltip title="Close" placement="left" arrow>
          <IconButton className="p-2 -mr-2" onClick={onClose}>
            <CrossSolidIcon fill={Colors.white.default} />
          </IconButton>
        </BlackTooltip>
      )}
    </div>
  );
};

DashboardBannerView.propTypes = {
  onClose: PropTypes.func,
  onAgree: PropTypes.func,
  startIcon: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

DashboardBannerView.defaultProps = {
  className: 'bg-red',
  startIcon: <BanIcon fill={Colors.white.default} />,
  onClose: null,
  onAgree: null
};

export default DashboardBannerView;
