import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { RoundedButton } from '@componentsV2';
import ROUTES from 'seller/routing';
import LearnMoreCard from './LearnMoreCard';
import { LEARN_MORE_POSTS } from './learnMorePosts';

const LearnMoreBlock = ({ history }) => {
  return (
    <div className="w-full flex flex-col items-center pt-6 pb-10 text-gray-darkest px-4 xl:px-0 xl:max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px">
      <div className="flex flex-col items-center">
        <div className="font_30px xl:font_40px xxl:font_50px font-bold mb-5 text-center">
          <span>Your Go-To Hub for </span>
          <span className="playfair_display italic">Explore, Learn, </span>
          <span>& </span>
          <span className="playfair_display italic">Grow</span>
        </div>
      </div>
      <div className="py-4 xl:py-12 gap-4 xl:gap-6 items-stretch grid grid-cols-2 xl:grid-cols-4">
        {LEARN_MORE_POSTS.map(post => (
          <LearnMoreCard
            key={post.id}
            image={post.preview_image}
            title={post.title}
            onClick={() => {
              history.push(`${ROUTES.learnMore}?postId=${post.id}`);
            }}
          />
        ))}
      </div>
      <Link to={ROUTES.learnMore} className="w-full flex justify-center mt-4 xl:mt-0">
        <RoundedButton text="Learn More" />
      </Link>
    </div>
  );
};

LearnMoreBlock.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(LearnMoreBlock);
