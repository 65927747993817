import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ROUTES from 'seller/routing';
import { updateProfileAction } from 'seller/store/actions';
import Colors from 'styles/colors';
import { isEmpty } from '@utils/types';
import ProgressBar from '@components/progress-bar';
import CheckItem from '@components/common/CheckItem';
import VideoDialog from '@components/dialogs/VideoDialog';
import { APPSTORE_LINK } from '@sm/consts';
import GetStartedCheckListImage from './GetStartedChecklist-image.png';

const dashbboardItem = 'check_out_the_dashboard';
const seeDemoVideoTitle = 'See Tutorial';

const items = [
  {
    id: 4,
    title: 'Check out the Dashboard',
    subtitle: 'Stay on top of your business',
    key: dashbboardItem,
    link: ROUTES.home
  },
  {
    id: 2,
    title: seeDemoVideoTitle,
    subtitle: 'A short, 14min intro to Seller Metrix',
    key: 'taken_tour'
  },
  {
    id: 0,
    title: 'Set your production cost',
    subtitle: 'For an accurate profit calculation',
    key: 'set_production_cost',
    link: ROUTES.products
  },
  {
    id: 1,
    title: 'Download Mobile App',
    subtitle: 'Available on iOS and Android',
    key: 'download_mobile_app',
    link: APPSTORE_LINK
  },
  {
    id: 5,
    title: 'Setup an Email Campaign',
    subtitle: 'Generate Amazon product reviews',
    key: 'email_followup',
    link: ROUTES.email_tool.root
  }
  /*
  FIXME: not using now
  {
    id: 2,
    title: 'Create your first note',
    subtitle: 'To keep track of your changes',
    key: 'create_your_first_note'
  },
  */
];

const PERCENT_PER_EL = 100 / items.length;

const getStartedCheckListStyle = {
  list: {
    width: '22rem'
  },
  item: {
    height: '3.25rem'
  }
};

function GetStartedChecklist({ keysAndValues }) {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);
  if (isEmpty(keysAndValues)) {
    return null;
  }

  const percentage = items.reduce(
    (acc, cur) =>
      cur.key === dashbboardItem || keysAndValues[cur.key] ? acc + PERCENT_PER_EL : acc,
    0
  );

  const handleItemClicked = title => {
    if (title === seeDemoVideoTitle) {
      setDialog(true);
      if (keysAndValues.taken_tour) return;
      dispatch(updateProfileAction({ taken_tour: true }, null, true));
    }
  };

  const rows = items.map(value => {
    const done = value.key === dashbboardItem || keysAndValues[value.key];
    return (
      <CheckItem
        key={value.title}
        label={value.title}
        subLabel={value.subtitle}
        done={done}
        className="w-full mt-2"
        style={getStartedCheckListStyle.item}
        onClick={handleItemClicked}
        link={value.link}
      />
    );
  });

  return (
    <div className="checklist rounded box-shadow-default" style={getStartedCheckListStyle.list}>
      <img src={GetStartedCheckListImage} className="rounded-top" alt="background" />
      <div className="p-4">
        <ProgressBar
          percentage={percentage}
          pathColor={Colors.green.lighter}
          className="mb-4 rounded-sm"
          pathClassName="rounded-sm"
        />
        <p className="sm:text-lg sm:font-medium">Get Started Checklist</p>
        {keysAndValues && rows}
      </div>
      <VideoDialog
        src="https://www.youtube.com/embed/a1t8olQHC7w"
        open={dialog}
        onClose={() => setDialog(null)}
      />
    </div>
  );
}

GetStartedChecklist.propTypes = {
  keysAndValues: PropTypes.objectOf(PropTypes.bool)
};

GetStartedChecklist.defaultProps = {
  keysAndValues: {}
};

export default GetStartedChecklist;
