import mixpanel from "mixpanel-browser";
import { useEffect } from 'react';

import store from 'seller/store';
import { isProductionStage } from "./build";

const MIX_PANEL_KEY = process.env.REACT_APP_MIX_PANEL_KEY;

const UserActivity = {
    LP1_VIEW: 'LPv1 View',
    SIGNUP_VIEW: 'Sign Up1 View',
    SIGNUP_FINISHED: 'Sign Up1 Finished',

    LP2_VIEW: 'LPv2 View',
    SIGNUP2_VIEW: 'Sign Up2 View',
    SIGNUP2_FINISHED: 'Sign Up2 Finished',

    CONNECT_SC_VIEW: 'Connect SC View',
    CONNECT_SC_CLICK: 'Connect SC Click',
    ONBOARDING_VIEW: 'Onboarding View',
};

export const initUserTracking = () => {
    if (!MIX_PANEL_KEY) return ;
    mixpanel.init(MIX_PANEL_KEY, {
        persistence: "localStorage",
    });
};

const mixPanelTrack = (userActivity) => {
    if (!MIX_PANEL_KEY) return;
    const userActivityName = isProductionStage() ? userActivity : `${userActivity}_DEV`
    mixpanel.track(userActivityName);
}

export const userTrackingReset = () => {
    if (!MIX_PANEL_KEY) return;
    mixpanel.reset();
}

export const utLp1View = () => mixPanelTrack(UserActivity.LP1_VIEW);

export const utSignupView = () => mixPanelTrack(UserActivity.SIGNUP_VIEW);

export const utSignupFinished = () => mixPanelTrack(UserActivity.SIGNUP_FINISHED);

export const utLp2View = () => mixPanelTrack(UserActivity.LP2_VIEW);

export const utSignup2View = () => mixPanelTrack(UserActivity.SIGNUP2_VIEW);

export const utSignup2Finished = () => mixPanelTrack(UserActivity.SIGNUP2_FINISHED);

export const utConnectSCView = () => mixPanelTrack(UserActivity.CONNECT_SC_VIEW);

export const utConnectSCClick = () => mixPanelTrack(UserActivity.CONNECT_SC_CLICK);

export const utOnboardingView = () => mixPanelTrack(UserActivity.ONBOARDING_VIEW);

export const utIdentify = (userId) => {
    if (!MIX_PANEL_KEY) return;
    mixpanel.identify(userId);
};


export const utPageView = (pageName) => {
    if (!MIX_PANEL_KEY) return;
    mixPanelTrack(pageName);
}

export const useUtPageView = (pageName) => {
    useEffect(() => {
        const { is_staff: isStaff  } = store.getState().user.profile;
        if (isStaff) return;
        const pageViewName = isProductionStage() ? pageName : `${pageName}_DEV`
        utPageView(pageViewName);
    }, [pageName]);
};
