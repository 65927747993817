import {
  getPostContentPictureByPostId,
  getPostPreviewImageByPostId,
  getPostTitlePictureByPostId
} from './img/learn-more-posts';

export const LEARN_MORE_POSTS = [
  {
    id: 'l-1',
    preview_image: getPostPreviewImageByPostId(1),
    title: 'Mastering Amazon PPC: Best Practices for Optimizing Ad Spend',
    title_image: getPostTitlePictureByPostId(1),
    intro:
      'Amazon PPC (Pay-Per-Click) campaigns are essential for driving traffic and increasing visibility. However, to truly master Amazon PPC, you need to understand what drives results and how to optimize your ad spend effectively.',
    points: [
      {
        title: 'Start with the Right Keywords',
        content:
          "Keywords are the foundation of every successful PPC campaign. Start by researching relevant keywords that match your product niche and buyer intent. Use tools like Amazon's search term report or keyword planners to identify high-impact keywords. Don't forget to categorize keywords by broad, phrase, and exact match to control ad spend more effectively."
      },
      {
        title: 'Optimize Bids and Budgets',
        content:
          'Effective budget allocation is key. Determine your daily budget based on your campaign goals, such as boosting sales or driving brand awareness. Adjust bids regularly by looking at conversion rates. If certain keywords are not converting well, lower their bids or pause them.'
      },
      {
        title: 'Track and Analyze Performance',
        content:
          'Constant monitoring is crucial. Make sure to evaluate key metrics, like click-through rate (CTR), cost per click (CPC), and Advertising Cost of Sales (ACoS). Use this data to tweak campaigns accordingly—eliminating low-performing keywords and increasing bids on those that perform well.'
      }
    ],
    content_image: getPostContentPictureByPostId(1),
    summary:
      "Amazon PPC campaigns are all about testing, optimizing, and scaling. With careful monitoring and tweaks, you'll see a boost in both traffic and sales.",
    cta:
      'Want to master your Amazon PPC strategy? Try SellerMetrix for 30 Days—free! Optimize your campaigns with real-time analytics.'
  },
  {
    id: 'l-2',
    preview_image: getPostPreviewImageByPostId(2),
    title: 'The Ultimate Guide to Tracking and Improving Profit Margins on Amazon',
    title_image: getPostTitlePictureByPostId(2),
    intro:
      'Understanding and improving your profit margins is key to building a sustainable Amazon business. By tracking all the necessary expenses, you can identify opportunities to cut costs and increase profitability.',
    points: [
      {
        title: 'Identify All Costs Involved',
        content:
          'The first step to improving profit margins is to identify all costs involved in selling on Amazon. This includes Amazon fees, shipping, inventory costs, and advertising expenses. A clear understanding of these costs will give you a complete picture of your profit margins.'
      },
      {
        title: 'Leverage the Profit & Loss Statement',
        content:
          'A detailed profit & loss (P&L) statement is essential for tracking profitability. Regularly update your P&L to account for changes in costs or revenue. This will help you identify trends and make informed decisions on pricing, inventory, and marketing efforts.'
      },
      {
        title: 'Optimize Advertising Spend',
        content:
          'Advertising costs can quickly eat into your profit margins if not managed properly. Regularly evaluate your PPC campaigns and cut back on underperforming ads. Focus on the campaigns that are driving the highest return on ad spend (ROAS).'
      }
    ],
    content_image: getPostContentPictureByPostId(2),
    summary:
      'Tracking and improving your profit margins requires detailed monitoring of your expenses and revenue. With the right tools, you can maximize your profits and grow your business.',
    cta:
      'Gain a complete view of your profit & loss with SellerMetrix! Try it free for 30 Days and start tracking your margins today.'
  },
  {
    id: 'l-3',
    preview_image: getPostPreviewImageByPostId(3),
    title: 'Winning the Buy Box: Proven Strategies for Maximum Visibility',
    title_image: getPostTitlePictureByPostId(3),
    intro:
      'Winning the Buy Box on Amazon is a crucial part of maximizing sales and growing your business. The Buy Box is where over 80% of all sales happen, and having your product featured there means significantly more exposure.',
    points: [
      {
        title: 'Price Competitively',
        content:
          'Price plays a major role in winning the Buy Box. Ensure that your pricing is competitive compared to other sellers. Using a dynamic pricing strategy can help you adjust prices based on competition, ensuring you remain eligible for the Buy Box.'
      },
      {
        title: 'Keep Inventory Levels High',
        content:
          'Having sufficient inventory is key. If your product runs out of stock, you lose the chance to win the Buy Box. Keep an eye on your inventory levels and consider using Fulfillment by Amazon (FBA) to improve your inventory management.'
      },
      {
        title: 'Maintain High Seller Performance Metrics',
        content:
          'Seller metrics such as order defect rate, late shipment rate, and customer feedback are critical. Aim for high ratings, on-time shipments, and excellent customer service to increase your chances of winning the Buy Box.'
      }
    ],
    content_image: getPostContentPictureByPostId(3),
    summary:
      'Winning the Buy Box requires a mix of competitive pricing, strong inventory management, and great customer service. Focus on these aspects to stay ahead of the competition.',
    cta:
      ' Ready to boost your Buy Box win rate? Sellermetrix can help! Try it free for 30 Days and gain insights to maximize your visibility.'
  },
  {
    id: 'l-4',
    preview_image: getPostPreviewImageByPostId(4),
    title: 'Creating Listings that Convert: Best Practices for Titles, Images, and Bullet Points',
    title_image: getPostTitlePictureByPostId(4),
    intro:
      'Your product listing is your first impression on potential buyers. Creating a high-converting listing requires compelling titles, quality images, and informative bullet points.',
    points: [
      {
        title: 'Craft Compelling Titles',
        content:
          'Your product title should be informative and keyword-rich while remaining easy to read. Include the most important features and benefits of your product, and ensure the title is clear and engaging.'
      },
      {
        title: 'Use High-Quality Images',
        content:
          'Images are critical to driving conversions. Use high-resolution images that show your product from multiple angles. Consider adding lifestyle images to help customers visualize using the product. Amazon also allows videos, which can further increase conversion rates.'
      },
      {
        title: 'Write Informative Bullet Points',
        content:
          'Bullet points should clearly convey the benefits of your product. Highlight features, answer common questions, and address potential pain points. Make sure the information is easy to skim, and avoid overly technical jargon.'
      }
    ],
    content_image: getPostContentPictureByPostId(4),
    summary:
      'A well-crafted listing can significantly increase conversions. Spend time optimizing each element to attract buyers and encourage them to make a purchase.',
    cta:
      ' Want to create listings that convert? Try SellerMetrix free for 30 Days and optimize your product pages.'
  }
];
