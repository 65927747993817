import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SnackbarContent, Snackbar, IconButton, withStyles } from '@material-ui/core';
import { isMediumSize, isSmallSize } from '@sm/WindowSizes';
import Colors from 'styles/colors';
import { CrossIcon, ExclamationIcon, CheckIcon } from '../icons';

const AUTO_HIDE_TIMEOUT = 2000;
export const SUCCESS = 'success';
export const WARNING = 'warning';

const getMessageMaxWidth = () => {
  if (isSmallSize()) return 'auto';
  return isMediumSize() ? '13.5rem !important' : '16rem !important';
};

const CustomSnackbarContent = withStyles({
  message: { '& p': { maxWidth: getMessageMaxWidth() } },
  root: { '&': { flexWrap: 'nowrap' } }
})(SnackbarContent);

const variantData = {
  [SUCCESS]: {
    icon: CheckIcon,
    className: 'bg-green-dark'
  },
  error: {
    icon: ExclamationIcon,
    className: 'bg-red'
  },
  [WARNING]: {
    icon: ExclamationIcon,
    className: 'bg-yellow-dark'
  }
};

function NotificationView(props) {
  const {
    message,
    messageType,
    onClose,
    nodeTypeMessage,
    primaryNotification,
    primaryNotificationType,
    nodeTypePrimaryNotification,
    autoHideMsg,
    closeOnBlur,
    onPrimaryClose
  } = props;

  const getMessageContent = (contentMessage, contentMessageType, contentNodeTypeMessage) => {
    const data = variantData[contentMessageType];
    return (
      <div id="notification-snackbar" className="flex items-center justify-between w-full">
        <div className="inline-flex items-center">
          {!contentNodeTypeMessage ? (
            <>
              <data.icon fill={Colors.white.default} />
              <p className="text-white ml-4 font-medium">{String(contentMessage)}</p>
            </>
          ) : (
            contentMessage
          )}
        </div>
      </div>
    );
  };

  const getActionContent = onClick => (
    <IconButton onClick={onClick}>
      <CrossIcon fill={Colors.white.default} className="w-4" />
    </IconButton>
  );

  return (
    <>
      {primaryNotification && (
        <Snackbar
          aria-describedby="notification-snackbar"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          className="z-9999"
          autoHideDuration={null}
          open
        >
          <CustomSnackbarContent
            className={variantData[primaryNotificationType].className}
            message={getMessageContent(
              primaryNotification,
              primaryNotificationType,
              nodeTypePrimaryNotification
            )}
            action={getActionContent(onPrimaryClose)}
          />
        </Snackbar>
      )}
      {message && (
        <Snackbar
          aria-describedby="notification-snackbar"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          className={classNames('z-9999', {
            'mt-24': primaryNotification
          })}
          autoHideDuration={autoHideMsg ? AUTO_HIDE_TIMEOUT : null}
          open
          onClose={closeOnBlur ? onClose : () => {}}
        >
          <CustomSnackbarContent
            className={variantData[messageType].className}
            message={getMessageContent(message, messageType, nodeTypeMessage)}
            action={getActionContent(onClose)}
          />
        </Snackbar>
      )}
    </>
  );
}

NotificationView.propTypes = {
  message: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  primaryNotification: PropTypes.string.isRequired,
  primaryNotificationType: PropTypes.string.isRequired,
  onPrimaryClose: PropTypes.func,
  nodeTypeMessage: PropTypes.bool,
  autoHideMsg: PropTypes.bool,
  closeOnBlur: PropTypes.bool
};

NotificationView.defaultProps = {
  nodeTypeMessage: false,
  autoHideMsg: false,
  closeOnBlur: true,
  primaryNotification: '',
  primaryNotificationType: 'error',
  onPrimaryClose: () => {}
};

export default NotificationView;
