import API from '@sm/api';
import mock from '../mock';

const dataResponseByMetric = {
  stats: {
    used_model: 'BrandDateSummaryByMonthsMirrorFirst',
    to_determine_proxy: 0.010666624642908573,
    to_proxy_blocking_lock: 0.0006518922746181488,
    to_table_to_select: 0.003091607242822647,
    to_data_pull: 0.011931397020816803,
    to_denormalize_metrics: 0.0008088452741503716,
    to_merge_by_intervals: 0.01864502113312483,
    to_merge_target_metrics: 0.0006129313260316849,
    to_get_children_structure: 0.00041896477341651917,
    to_fill_children_structure_items: 0.0030897166579961777,
    to_processing_total: 0.02357547916471958
  },
  config: {
    levels: ['metric'],
    time_grain: 'month',
    dates: [
      '2024-09-01',
      '2024-08-01',
      '2024-07-01',
      '2024-06-01',
      '2024-05-01',
      '2024-04-01',
      '2024-03-01'
    ]
  },
  data: [
    {
      type: 'metric',
      metadata: {
        name: 'gross_sales',
        type: 'metric'
      },
      name: 'gross_sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'refunds',
            type: 'metric'
          },
          name: 'refunds',
          children: [],
          values: [-6073.37, -1097.12, -680.93, -830.22, -700.87, -927.64, -910.15, -926.44],
          targets: [
            -13584.96,
            -1938.22,
            -1951.17,
            -2032.73,
            -1946.41,
            -1919.55,
            -1892.33,
            -1904.55
          ],
          values_percentages: [1.08, 1.49, 0.91, 1.03, 0.92, 1.07, 1.11, 1.05],
          targets_percentages: [2.12, 2.12, 2.12, 2.1, 2.12, 2.12, 2.12, 2.12]
        },
        {
          type: 'metric',
          metadata: {
            name: 'discounts',
            type: 'metric'
          },
          name: 'discounts',
          children: [],
          values: [-16265.85, -2732.32, -2534.77, -3226.73, -2560.2, -2278.83, -1548.39, -1384.61],
          targets: [-13754.32, -1723.2, -1837.99, -2306.09, -1839.0, -2102.11, -1778.1, -2167.83],
          values_percentages: [2.9, 3.71, 3.39, 4.01, 3.38, 2.64, 1.89, 1.56],
          targets_percentages: [2.14, 1.88, 1.99, 2.39, 2.0, 2.32, 1.99, 2.42]
        },
        {
          type: 'metric',
          metadata: {
            name: 'other_credits_and_refunds',
            type: 'metric'
          },
          name: 'other_credits_and_refunds',
          children: [],
          values: [9646.64, 1265.72, 1057.46, 1138.43, 1623.17, 1075.77, 2109.68, 1376.41],
          targets: [12494.92, 1785.0, 1784.98, 1784.98, 1785.0, 1784.98, 1785.0, 1784.98],
          values_percentages: [1.72, 1.72, 1.41, 1.41, 2.14, 1.25, 2.58, 1.56],
          targets_percentages: [1.95, 1.95, 1.94, 1.85, 1.94, 1.97, 2.0, 1.99]
        }
      ],
      values: [561440.08, 73703.27, 74872.12, 80465.01, 75791.36, 86301.01, 81807.23, 88500.08],
      targets: [641408.5, 91432.82, 92162.09, 96572.86, 91812.0, 90537.84, 89127.9, 89762.99],
      values_percentages: [100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0],
      targets_percentages: [100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0, 100.0]
    },
    {
      type: 'metric',
      metadata: {
        name: 'net_sales',
        type: 'metric'
      },
      name: 'net_sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'net_cogs',
            type: 'metric'
          },
          name: 'net_cogs',
          children: [],
          values: [
            -192094.22,
            -23141.56,
            -25498.88,
            -28111.92,
            -24935.07,
            -30002.15,
            -28853.9,
            -31550.74
          ],
          targets: [
            -209239.55,
            -29625.92,
            -29732.13,
            -32184.24,
            -29577.9,
            -29219.31,
            -29307.9,
            -29592.15
          ],
          values_percentages: [35.01, 32.53, 35.07, 36.25, 33.63, 35.64, 35.42, 36.03],
          targets_percentages: [33.39, 33.08, 32.98, 34.23, 32.93, 33.09, 33.59, 33.83]
        }
      ],
      values: [548747.5, 71139.55, 72713.88, 77546.49, 74153.46, 84170.31, 81458.37, 87565.44],
      targets: [626568.3, 89557.83, 90157.67, 94020.76, 89811.89, 88302.69, 87242.77, 87474.69],
      values_percentages: [97.74, 96.52, 97.12, 96.37, 97.84, 97.53, 99.57, 98.94],
      targets_percentages: [97.69, 97.95, 97.83, 97.36, 97.82, 97.53, 97.88, 97.45]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_1',
        type: 'metric'
      },
      name: 'cm_1',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'marketplace_costs',
            type: 'metric'
          },
          name: 'marketplace_costs',
          children: [],
          values: [
            -260011.99,
            -31165.45,
            -37875.74,
            -43973.92,
            -33757.09,
            -37092.72,
            -37128.84,
            -39018.23
          ],
          targets: [
            -268629.54,
            -38071.1,
            -38405.93,
            -40977.08,
            -38279.15,
            -37700.02,
            -37507.22,
            -37689.04
          ],
          values_percentages: [47.38, 43.81, 52.09, 56.71, 45.52, 44.07, 45.58, 44.56],
          targets_percentages: [42.87, 42.51, 42.6, 43.58, 42.62, 42.69, 42.99, 43.09]
        },
        {
          type: 'metric',
          metadata: {
            name: 'non_marketplace_costs',
            type: 'metric'
          },
          name: 'non_marketplace_costs',
          children: [],
          values: [
            -168406.72,
            -69840.0,
            -13677.51,
            -1316.88,
            -34077.3,
            -26654.73,
            -15294.9,
            -7545.4
          ],
          targets: [-65589.17, -6024.0, -6018.03, -15212.01, -7325.1, -7307.01, -16494.9, -7208.12],
          values_percentages: [30.69, 98.17, 18.81, 1.7, 45.96, 31.67, 18.78, 8.62],
          targets_percentages: [10.47, 6.73, 6.68, 16.18, 8.16, 8.27, 18.91, 8.24]
        }
      ],
      values: [356653.1, 47998.04, 47214.91, 49434.44, 49218.42, 54168.26, 52604.35, 56014.68],
      targets: [417330.17, 59931.98, 60425.83, 61837.04, 60233.69, 59084.09, 57934.87, 57882.67],
      values_percentages: [64.99, 67.47, 64.93, 63.75, 66.37, 64.36, 64.58, 63.97],
      targets_percentages: [66.61, 66.92, 67.02, 65.77, 67.07, 66.91, 66.41, 66.17]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_2',
        type: 'metric'
      },
      name: 'cm_2',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'marketing_and_advertising',
            type: 'metric'
          },
          name: 'marketing_and_advertising',
          children: [],
          values: [-46835.68, -7232.31, -7415.49, -7398.21, -6732.43, -6219.52, -5841.98, -5995.74],
          targets: [
            -79648.41,
            -13506.9,
            -9048.28,
            -10716.08,
            -8670.0,
            -13074.25,
            -16665.9,
            -7967.0
          ],
          values_percentages: [8.54, 10.17, 10.2, 9.54, 9.08, 7.39, 7.17, 6.85],
          targets_percentages: [12.71, 15.08, 10.04, 11.4, 9.65, 14.81, 19.1, 9.11]
        }
      ],
      values: [-71765.31, -53007.38, -4338.24, 4143.73, -18615.9, -9579.13, 180.65, 9450.96],
      targets: [83103.36, 15834.56, 16001.31, 5646.52, 14627.22, 14075.64, 3931.45, 12986.66],
      values_percentages: [13.08, 74.51, 5.97, 5.34, 25.1, 11.38, 0.22, 10.79],
      targets_percentages: [13.26, 17.68, 17.75, 6.01, 16.29, 15.94, 4.51, 14.85]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_3',
        type: 'metric'
      },
      name: 'cm_3',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'operating_expenses',
            type: 'metric'
          },
          name: 'operating_expenses',
          children: [],
          values: [-73252.47, -1511.4, -14854.27, 17578.24, 17421.6, -13868.78, -71158.8, -6859.06],
          targets: [-10483.03, -1446.9, -1447.08, -1487.07, -1446.9, -1567.98, -1568.1, -1519.0],
          values_percentages: [13.35, 2.12, 20.43, 22.67, 23.49, 16.48, 87.36, 7.83],
          targets_percentages: [1.67, 1.62, 1.61, 1.58, 1.61, 1.78, 1.8, 1.74]
        },
        {
          type: 'metric',
          metadata: {
            name: 'other_income',
            type: 'metric'
          },
          name: 'other_income',
          children: [],
          values: [2250.9, 2250.9, 0, 0, 0, 0, 0, 0],
          targets: [0, 0, 0, 0, 0, 0, 0, 0],
          values_percentages: [0.41, 3.16, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
          targets_percentages: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
        }
      ],
      values: [-118601.09, -60239.8, -11753.73, -3254.49, -25348.33, -15798.62, -5661.33, 3455.21],
      targets: [3457.71, 2328.56, 6953.65, -5069.25, 5958.42, 1002.63, -12735.65, 5019.35],
      values_percentages: [21.61, 84.68, 16.16, 4.2, 34.18, 18.77, 6.95, 3.95],
      targets_percentages: [0.55, 2.6, 7.71, 5.39, 6.63, 1.14, 14.6, 5.74]
    },
    {
      type: 'metric',
      metadata: {
        name: 'cm_4',
        type: 'metric'
      },
      name: 'cm_4',
      children: [],
      values: [-189602.66, -59500.3, -26608.0, 14323.75, -7926.73, -29667.4, -76820.13, -3403.85],
      targets: [-7029.29, 880.46, 5505.64, -6556.32, 4510.62, -566.59, -14303.45, 3500.35],
      values_percentages: [34.55, 83.64, 36.59, 18.47, 10.69, 35.25, 94.31, 3.89],
      targets_percentages: [1.12, 0.98, 6.11, 6.97, 5.02, 0.64, 16.39, 4.0]
    }
  ]
};

const dataResponseByMetricAndProduct = {
  stats: {
    used_model: 'ProductDateSummaryMetrics',
    to_determine_proxy: 0.012189069297164679,
    to_proxy_blocking_lock: 0.0010043289512395859,
    to_table_to_select: 0.002785707823932171,
    to_data_pull: 0.06650258600711823,
    to_denormalize_metrics: 0.12884245719760656,
    to_merge_by_intervals: 0.16351784812286496,
    to_merge_target_metrics: 0.0003564278595149517,
    to_get_children_structure: 0.0011059921234846115,
    to_fill_children_structure_items: 0.05138530395925045,
    to_fill_products_details: 0.004719913005828857,
    to_processing_total: 0.3499279422685504
  },
  config: {
    levels: ['metric', 'product'],
    time_grain: 'month',
    dates: [
      '2024-11-01',
      '2024-10-01',
      '2024-09-01',
      '2024-08-01',
      '2024-07-01',
      '2024-06-01',
      '2024-05-01'
    ]
  },
  data: [
    {
      type: 'metric',
      metadata: {
        name: 'Product Sales',
        type: 'metric'
      },
      name: 'Product Sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'Shipping',
            type: 'metric'
          },
          name: 'Shipping',
          children: [
            {
              type: 'product',
              name: 6367543,
              metadata: {
                id: 6367543,
                type: 'product'
              },
              values: [0, 0, 0, 0, 0, 0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0, 0, 0, 0, 0, 0],
              targets_percentages: []
            },
            {
              type: 'product',
              name: 6367519,
              metadata: {
                id: 6367519,
                type: 'product'
              },
              values: [-30.37, 0, 0, -4.21, 0, -3.92, -2.73, -19.51],
              targets: [],
              values_percentages: [0.01, 0, 0, 0.01, 0, 0.01, 0, 0.04],
              targets_percentages: []
            }
          ],
          values: [-30.37, 0, 0, -4.21, 0, -3.92, -2.73, -19.51],
          targets: [],
          values_percentages: [0.01, 0, 0, 0.01, 0, 0.01, 0, 0.04],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'Discounts',
            type: 'metric'
          },
          name: 'Discounts',
          children: [
            {
              type: 'product',
              name: 6367543,
              metadata: {
                id: 6367543,
                type: 'product'
              },
              values: [-1764.7, 0, -120, -340, -500, -440, -364.7, 0],
              targets: [],
              values_percentages: [0.46, 0, 0.27, 0.57, 0.85, 0.68, 0.59, 0],
              targets_percentages: []
            },
            {
              type: 'product',
              name: 6367519,
              metadata: {
                id: 6367519,
                type: 'product'
              },
              values: [
                -46078.52,
                -5413.44,
                -6390.43,
                -7686.82,
                -4994.32,
                -8082.29,
                -6808.22,
                -6703
              ],
              targets: [],
              values_percentages: [12.13, 14.13, 14.44, 12.94, 8.52, 12.4, 11.04, 12.77],
              targets_percentages: []
            }
          ],
          values: [-47843.22, -5413.44, -6510.43, -8026.82, -5494.32, -8522.29, -7172.92, -6703],
          targets: [],
          values_percentages: [12.59, 14.13, 14.71, 13.51, 9.37, 13.08, 11.63, 12.77],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'Refunds Total',
            type: 'metric'
          },
          name: 'Refunds Total',
          children: [
            {
              type: 'product',
              name: 6367543,
              metadata: {
                id: 6367543,
                type: 'product'
              },
              values: [-4187.56, -140.72, -468.16, -954.08, -1167.63, -868.56, -504.45, -83.96],
              targets: [],
              values_percentages: [1.1, 0.37, 1.06, 1.61, 1.99, 1.33, 0.82, 0.16],
              targets_percentages: []
            },
            {
              type: 'product',
              name: 6367519,
              metadata: {
                id: 6367519,
                type: 'product'
              },
              values: [-4205.11, -507.3, -580.41, -621.64, -526.16, -763.02, -516.06, -690.52],
              targets: [],
              values_percentages: [1.11, 1.32, 1.31, 1.05, 0.9, 1.17, 0.84, 1.32],
              targets_percentages: []
            }
          ],
          values: [-8392.67, -648.02, -1048.57, -1575.72, -1693.79, -1631.58, -1020.51, -774.48],
          targets: [],
          values_percentages: [2.21, 1.69, 2.37, 2.65, 2.89, 2.5, 1.66, 1.48],
          targets_percentages: []
        }
      ],
      values: [379890.02, 38299.59, 44254.34, 59400.14, 58615.83, 65179.02, 61662.17, 52478.93],
      targets: [],
      values_percentages: [100, 100, 100, 100, 100, 100, 100, 100],
      targets_percentages: []
    },
    {
      type: 'metric',
      metadata: {
        name: 'Net Sales',
        type: 'metric'
      },
      name: 'Net Sales',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'CoGS',
            type: 'metric'
          },
          name: 'CoGS',
          children: [
            {
              type: 'product',
              name: 6367543,
              metadata: {
                id: 6367543,
                type: 'product'
              },
              values: [
                -75322.24,
                -2266.88,
                -4894.4,
                -12158.72,
                -20195.84,
                -16331.84,
                -18135.04,
                -1339.52
              ],
              targets: [],
              values_percentages: [23.27, 7.03, 13.34, 24.42, 39.27, 29.68, 33.92, 2.98],
              targets_percentages: []
            },
            {
              type: 'product',
              name: 6367519,
              metadata: {
                id: 6367519,
                type: 'product'
              },
              values: [
                -63916.02,
                -7639.02,
                -7705.62,
                -10429.56,
                -8045.28,
                -10742.58,
                -8784.54,
                -10569.42
              ],
              targets: [],
              values_percentages: [19.75, 23.7, 21, 20.95, 15.64, 19.52, 16.43, 23.5],
              targets_percentages: []
            }
          ],
          values: [
            -139238.26,
            -9905.9,
            -12600.02,
            -22588.28,
            -28241.12,
            -27074.42,
            -26919.58,
            -11908.94
          ],
          targets: [],
          values_percentages: [43.02, 30.73, 34.34, 45.36, 54.91, 49.21, 50.35, 26.47],
          targets_percentages: []
        }
      ],
      values: [323623.76, 32238.13, 36695.34, 49793.39, 51427.72, 55021.23, 53466.01, 44981.94],
      targets: [],
      values_percentages: [85.19, 84.17, 82.92, 83.83, 87.74, 84.42, 86.71, 85.71],
      targets_percentages: []
    },
    {
      type: 'metric',
      metadata: {
        name: 'CM1',
        type: 'metric'
      },
      name: 'CM1',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'Amazon Fees',
            type: 'metric'
          },
          name: 'Amazon Fees',
          children: [
            {
              type: 'product',
              name: 6367543,
              metadata: {
                id: 6367543,
                type: 'product'
              },
              values: [
                -27688.34,
                -772.5734,
                -1884.8566,
                -4497.89,
                -7385.54,
                -5955.18,
                -6688.33,
                -503.97
              ],
              targets: [],
              values_percentages: [8.56, 2.4, 5.14, 9.03, 14.36, 10.82, 12.51, 1.12],
              targets_percentages: []
            },
            {
              type: 'product',
              name: 6367519,
              metadata: {
                id: 6367519,
                type: 'product'
              },
              values: [
                -100039.6399,
                -12803.4895,
                -12399.0906,
                -15607.633,
                -12806.3606,
                -16219.8663,
                -13893.9466,
                -16309.2533
              ],
              targets: [],
              values_percentages: [30.91, 39.72, 33.79, 31.34, 24.9, 29.48, 25.99, 36.26],
              targets_percentages: []
            }
          ],
          values: [
            -127727.9799,
            -13576.0629,
            -14283.9472,
            -20105.523,
            -20191.9006,
            -22175.0463,
            -20582.2766,
            -16813.2233
          ],
          targets: [],
          values_percentages: [39.47, 42.11, 38.93, 40.38, 39.26, 40.3, 38.5, 37.38],
          targets_percentages: []
        }
      ],
      values: [184385.5, 22332.23, 24095.32, 27205.11, 23186.6, 27946.81, 26546.43, 33073],
      targets: [],
      values_percentages: [56.98, 69.27, 65.66, 54.64, 45.09, 50.79, 49.65, 73.53],
      targets_percentages: []
    },
    {
      type: 'metric',
      metadata: {
        name: 'CM2',
        type: 'metric'
      },
      name: 'CM2',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'Advertising',
            type: 'metric'
          },
          name: 'Advertising',
          children: [
            {
              type: 'product',
              name: 6367543,
              metadata: {
                id: 6367543,
                type: 'product'
              },
              values: [-4567.98, -419.83, -338.4, -678.54, -651.7, -280.56, -2150.14, -48.81],
              targets: [],
              values_percentages: [1.41, 1.3, 0.92, 1.36, 1.27, 0.51, 4.02, 0.11],
              targets_percentages: []
            },
            {
              type: 'product',
              name: 6367519,
              metadata: {
                id: 6367519,
                type: 'product'
              },
              values: [
                -13126.76,
                -20.22,
                -3168.87,
                -4080.56,
                -1879.81,
                -1663.22,
                -606.86,
                -1707.22
              ],
              targets: [],
              values_percentages: [4.06, 0.06, 8.64, 8.19, 3.66, 3.02, 1.14, 3.8],
              targets_percentages: []
            }
          ],
          values: [-17694.74, -440.05, -3507.27, -4759.1, -2531.51, -1943.78, -2757, -1756.03],
          targets: [],
          values_percentages: [5.47, 1.36, 9.56, 9.56, 4.92, 3.53, 5.16, 3.9],
          targets_percentages: []
        }
      ],
      values: [
        56657.5201,
        8756.1671,
        9811.3728,
        7099.587,
        2994.6994,
        5771.7637,
        5964.1534,
        16259.7767
      ],
      targets: [],
      values_percentages: [17.51, 27.16, 26.74, 14.26, 5.82, 10.49, 11.16, 36.15],
      targets_percentages: []
    },
    {
      type: 'metric',
      metadata: {
        name: 'CM3',
        type: 'metric'
      },
      name: 'CM3',
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'Other Expenses',
            type: 'metric'
          },
          name: 'Other Expenses',
          children: [
            {
              type: 'product',
              name: 6367543,
              metadata: {
                id: 6367543,
                type: 'product'
              },
              values: [0, 0, 0, 0, 0, 0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0, 0, 0, 0, 0, 0],
              targets_percentages: []
            },
            {
              type: 'product',
              name: 6367519,
              metadata: {
                id: 6367519,
                type: 'product'
              },
              values: [0, 0, 0, 0, 0, 0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0, 0, 0, 0, 0, 0],
              targets_percentages: []
            }
          ],
          values: [0, 0, 0, 0, 0, 0, 0, 0],
          targets: [],
          values_percentages: [0, 0, 0, 0, 0, 0, 0, 0],
          targets_percentages: []
        }
      ],
      values: [
        38962.7801,
        8316.1171,
        6304.1028,
        2340.487,
        463.1894,
        3827.9837,
        3207.1534,
        14503.7467
      ],
      targets: [],
      values_percentages: [12.04, 25.8, 17.18, 4.7, 0.9, 6.96, 6, 32.24],
      targets_percentages: []
    },
    {
      type: 'metric',
      metadata: {
        name: 'Net Profit',
        type: 'metric'
      },
      name: 'Net Profit',
      children: [
        {
          type: 'product',
          name: 6367543,
          metadata: {
            id: 6367543,
            type: 'product'
          },
          values: [
            -51135.47,
            -1972.2034,
            -3805.5166,
            -8760.93,
            -13140.16,
            -10681.94,
            -12047.21,
            -727.51
          ],
          targets: [],
          values_percentages: [15.8, 6.12, 10.37, 17.59, 25.55, 19.41, 22.53, 1.62],
          targets_percentages: []
        },
        {
          type: 'product',
          name: 6367519,
          metadata: {
            id: 6367519,
            type: 'product'
          },
          values: [
            90098.2501,
            10288.3205,
            10109.6194,
            11101.417,
            13603.3494,
            14509.9237,
            15254.3634,
            15231.2567
          ],
          targets: [],
          values_percentages: [27.84, 31.91, 27.55, 22.29, 26.45, 26.37, 28.53, 33.86],
          targets_percentages: []
        }
      ],
      values: [
        38962.7801,
        8316.1171,
        6304.1028,
        2340.487,
        463.1894,
        3827.9837,
        3207.1534,
        14503.7467
      ],
      targets: [],
      values_percentages: [12.04, 25.8, 17.18, 4.7, 0.9, 6.96, 6, 32.24],
      targets_percentages: []
    }
  ],
  product_details: {
    '6367519': {
      id: 6367519,
      asin: 'H515DFT59R',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      seller_sku: 'GG-51B4-DTG2',
      marketplace_id: 3,
      smallimage: 'https://m.media-amazon.com/images/I/81NXW0pDhCL._SL75_.jpg',
      variation_type_one: 'size',
      variation_value_one: '20.8 Ounce (Pack of 1)',
      variation_type_two: '',
      variation_value_two: '',
      variation_type_three: '',
      variation_value_three: '',
      variation_type_four: '',
      variation_value_four: '',
      variation_type_five: '',
      variation_value_five: ''
    },
    '6367543': {
      id: 6367543,
      asin: 'A554K46TJL',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      seller_sku: 'BU-4HE3-PV122',
      marketplace_id: 3,
      smallimage: 'https://m.media-amazon.com/images/I/91EfEsRNPrL._SL75_.jpg',
      variation_type_one: 'style',
      variation_value_one: '0 33-Pcs Metric/Inch Tool Roll',
      variation_type_two: '',
      variation_value_two: '',
      variation_type_three: '',
      variation_value_three: '',
      variation_type_four: '',
      variation_value_four: '',
      variation_type_five: '',
      variation_value_five: ''
    }
  }
};

const dataResponseByProductAndMetric = {
  stats: {
    used_model: 'ProductDateSummaryMetrics',
    to_determine_proxy: 0.02330442937090993,
    to_proxy_blocking_lock: 0.0010526878759264946,
    to_table_to_select: 0.004250515718013048,
    to_data_pull: 0.07727384427562356,
    to_denormalize_metrics: 0.16549234371632338,
    to_merge_by_intervals: 0.23288026312366128,
    to_merge_target_metrics: 0.00018897093832492828,
    to_get_children_structure: 0.0008827103301882744,
    to_fill_children_structure_items: 0.07264091772958636,
    to_fill_products_details: 0.005304036196321249,
    to_processing_total: 0.47738924203440547
  },
  config: {
    levels: ['product', 'metric'],
    time_grain: 'month',
    dates: [
      '2024-11-01',
      '2024-10-01',
      '2024-09-01',
      '2024-08-01',
      '2024-07-01',
      '2024-06-01',
      '2024-05-01',
      '2024-04-01',
      '2024-03-01',
      '2024-02-01',
      '2024-01-01',
      '2023-12-01'
    ]
  },
  data: [
    {
      type: 'product',
      name: 6367543,
      metadata: {
        id: 6367543,
        type: 'product'
      },
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'Product Sales',
            type: 'metric'
          },
          name: 'Product Sales',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Shipping',
                type: 'metric'
              },
              name: 'Shipping',
              children: [],
              values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'Discounts',
                type: 'metric'
              },
              name: 'Discounts',
              children: [],
              values: [
                -50120.18,
                -4680,
                -3120,
                -4550,
                -3900,
                -6580,
                -4810,
                -6370,
                -5720,
                -7249.6,
                -1430,
                -210.44,
                -1500.14
              ],
              targets: [],
              values_percentages: [
                13.26,
                15.27,
                15.63,
                17.59,
                14.74,
                13.88,
                17.9,
                19.61,
                18.96,
                18.66,
                5.37,
                0.73,
                3.44
              ],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'Refunds Total',
                type: 'metric'
              },
              name: 'Refunds Total',
              children: [],
              values: [
                -7296.09,
                -101.35,
                -275.38,
                -101.35,
                -1018.44,
                -1138.24,
                -524.88,
                -626.79,
                -773.83,
                -983.16,
                -174.99,
                -1041.41,
                -536.27
              ],
              targets: [],
              values_percentages: [
                1.93,
                0.33,
                1.38,
                0.39,
                3.85,
                2.4,
                1.95,
                1.93,
                2.56,
                2.53,
                0.66,
                3.6,
                1.23
              ],
              targets_percentages: []
            }
          ],
          values: [
            377970.95,
            30644.27,
            19955.55,
            25865.5,
            26462.95,
            47411.75,
            26867.55,
            32479.25,
            30173.75,
            38853.95,
            26640.8,
            28966.71,
            43648.92
          ],
          targets: [],
          values_percentages: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'Net Sales',
            type: 'metric'
          },
          name: 'Net Sales',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'CoGS',
                type: 'metric'
              },
              name: 'CoGS',
              children: [],
              values: [
                -127418.91,
                -10820.67,
                -6624.9,
                -8391.54,
                -9348.47,
                -17077.52,
                -8097.1,
                -10084.57,
                -9422.08,
                -12072.04,
                -9127.64,
                -10747.06,
                -15605.32
              ],
              targets: [],
              values_percentages: [
                39.75,
                41.84,
                40.01,
                39.56,
                43.39,
                43.02,
                37.6,
                39.57,
                39.79,
                39.42,
                36.46,
                38.78,
                37.5
              ],
              targets_percentages: []
            }
          ],
          values: [
            320554.68,
            25862.92,
            16560.17,
            21214.15,
            21544.51,
            39693.51,
            21532.67,
            25482.46,
            23679.92,
            30621.19,
            25035.81,
            27714.86,
            41612.51
          ],
          targets: [],
          values_percentages: [
            84.81,
            84.4,
            82.99,
            82.02,
            81.41,
            83.72,
            80.14,
            78.46,
            78.48,
            78.81,
            93.98,
            95.68,
            95.33
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'CM1',
            type: 'metric'
          },
          name: 'CM1',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Amazon Fees',
                type: 'metric'
              },
              name: 'Amazon Fees',
              children: [],
              values: [
                -68398.8254,
                -5779.1914,
                -3516.4629,
                -4387.0148,
                -4708.0121,
                -8497.951,
                -4448.1272,
                -5309.0759,
                -5023.3971,
                -6461.5285,
                -5170.854,
                -6212.0811,
                -8885.1294
              ],
              targets: [],
              values_percentages: [
                21.34,
                22.35,
                21.23,
                20.68,
                21.85,
                21.41,
                20.66,
                20.83,
                21.21,
                21.1,
                20.65,
                22.41,
                21.35
              ],
              targets_percentages: []
            }
          ],
          values: [
            193135.77,
            15042.25,
            9935.27,
            12822.61,
            12196.04,
            22615.99,
            13435.57,
            15397.89,
            14257.84,
            18549.15,
            15908.17,
            16967.8,
            26007.19
          ],
          targets: [],
          values_percentages: [
            60.25,
            58.16,
            59.99,
            60.44,
            56.61,
            56.98,
            62.4,
            60.43,
            60.21,
            60.58,
            63.54,
            61.22,
            62.5
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'CM2',
            type: 'metric'
          },
          name: 'CM2',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Advertising',
                type: 'metric'
              },
              name: 'Advertising',
              children: [],
              values: [
                -27490.81,
                -387.09,
                0,
                -2414.19,
                -1483.12,
                -2252.46,
                -2109.28,
                -2138.3,
                -3576.91,
                -3575,
                -2407.23,
                -2565.75,
                -4581.48
              ],
              targets: [],
              values_percentages: [
                8.58,
                1.5,
                0,
                11.38,
                6.88,
                5.67,
                9.8,
                8.39,
                15.11,
                11.67,
                9.62,
                9.26,
                11.01
              ],
              targets_percentages: []
            }
          ],
          values: [
            124736.9446,
            9263.0586,
            6418.8071,
            8435.5952,
            7488.0279,
            14118.039,
            8987.4428,
            10088.8141,
            9234.4429,
            12087.6215,
            10737.316,
            10755.7189,
            17122.0606
          ],
          targets: [],
          values_percentages: [
            38.91,
            35.82,
            38.76,
            39.76,
            34.76,
            35.57,
            41.74,
            39.59,
            39,
            39.47,
            42.89,
            38.81,
            41.15
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'CM3',
            type: 'metric'
          },
          name: 'CM3',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Other Expenses',
                type: 'metric'
              },
              name: 'Other Expenses',
              children: [],
              values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              targets_percentages: []
            }
          ],
          values: [
            97246.1346,
            8875.9686,
            6418.8071,
            6021.4052,
            6004.9079,
            11865.579,
            6878.1628,
            7950.5141,
            5657.5329,
            8512.6215,
            8330.086,
            8189.9689,
            12540.5806
          ],
          targets: [],
          values_percentages: [
            30.34,
            34.32,
            38.76,
            28.38,
            27.87,
            29.89,
            31.94,
            31.2,
            23.89,
            27.8,
            33.27,
            29.55,
            30.14
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'Net Profit',
            type: 'metric'
          },
          name: 'Net Profit',
          children: [],
          values: [
            97246.1346,
            8875.9686,
            6418.8071,
            6021.4052,
            6004.9079,
            11865.579,
            6878.1628,
            7950.5141,
            5657.5329,
            8512.6215,
            8330.086,
            8189.9689,
            12540.5806
          ],
          targets: [],
          values_percentages: [
            30.34,
            34.32,
            38.76,
            28.38,
            27.87,
            29.89,
            31.94,
            31.2,
            23.89,
            27.8,
            33.27,
            29.55,
            30.14
          ],
          targets_percentages: []
        }
      ],
      values: [],
      targets: [],
      values_percentages: [],
      targets_percentages: []
    },
    {
      type: 'product',
      name: 6367519,
      metadata: {
        id: 6367519,
        type: 'product'
      },
      children: [
        {
          type: 'metric',
          metadata: {
            name: 'Product Sales',
            type: 'metric'
          },
          name: 'Product Sales',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Shipping',
                type: 'metric'
              },
              name: 'Shipping',
              children: [],
              values: [-37.29, 0, 0, -4.21, 0, -3.92, -2.73, -19.51, 0, -3.09, 0, 0, -3.83],
              targets: [],
              values_percentages: [0.01, 0, 0, 0.01, 0, 0.01, 0.01, 0.04, 0, 0.01, 0, 0, 0.01],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'Discounts',
                type: 'metric'
              },
              name: 'Discounts',
              children: [],
              values: [
                -88273.38,
                -5413.44,
                -6390.43,
                -7686.82,
                -4994.32,
                -8082.29,
                -6808.22,
                -6703,
                -7944.61,
                -9377.05,
                -8892.98,
                -7304.94,
                -8675.28
              ],
              targets: [],
              values_percentages: [
                14.19,
                14.76,
                15.84,
                15.52,
                11.93,
                15.55,
                14.84,
                13.08,
                15.18,
                15.47,
                13.76,
                12.09,
                12.98
              ],
              targets_percentages: []
            },
            {
              type: 'metric',
              metadata: {
                name: 'Refunds Total',
                type: 'metric'
              },
              name: 'Refunds Total',
              children: [],
              values: [
                -7858.01,
                -507.3,
                -580.41,
                -621.64,
                -526.16,
                -763.02,
                -516.06,
                -690.52,
                -618.24,
                -922.22,
                -496.18,
                -882.76,
                -733.5
              ],
              targets: [],
              values_percentages: [
                1.26,
                1.38,
                1.44,
                1.26,
                1.26,
                1.47,
                1.13,
                1.35,
                1.18,
                1.52,
                0.77,
                1.46,
                1.1
              ],
              targets_percentages: []
            }
          ],
          values: [
            622288.25,
            36671.79,
            40354.04,
            49531.84,
            41855.28,
            51984.82,
            45866.72,
            51230.18,
            52328.23,
            60627.07,
            64612.08,
            60415.5,
            66810.7
          ],
          targets: [],
          values_percentages: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'Net Sales',
            type: 'metric'
          },
          name: 'Net Sales',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'CoGS',
                type: 'metric'
              },
              name: 'CoGS',
              children: [],
              values: [
                -126999.54,
                -7639.02,
                -7705.62,
                -10429.56,
                -8045.28,
                -10742.58,
                -8784.54,
                -10569.42,
                -10762.56,
                -12580.74,
                -13333.32,
                -12520.8,
                -13886.1
              ],
              targets: [],
              values_percentages: [
                24.14,
                24.84,
                23.08,
                25.3,
                22.14,
                24.9,
                22.79,
                24.12,
                24.59,
                25,
                24.14,
                23.97,
                24.19
              ],
              targets_percentages: []
            }
          ],
          values: [
            526119.57,
            30751.05,
            33383.2,
            41219.17,
            36334.8,
            43135.59,
            38539.71,
            43817.15,
            43765.38,
            50324.71,
            55222.92,
            52227.8,
            57398.09
          ],
          targets: [],
          values_percentages: [
            84.55,
            83.85,
            82.73,
            83.22,
            86.81,
            82.98,
            84.03,
            85.53,
            83.64,
            83.01,
            85.47,
            86.45,
            85.91
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'CM1',
            type: 'metric'
          },
          name: 'CM1',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Amazon Fees',
                type: 'metric'
              },
              name: 'Amazon Fees',
              children: [],
              values: [
                -203575.7727,
                -12803.4895,
                -12399.0906,
                -15607.633,
                -12806.3606,
                -16219.8663,
                -13893.9466,
                -16309.2533,
                -16632.6966,
                -19670.2912,
                -21197.5837,
                -21957.8972,
                -24077.6641
              ],
              targets: [],
              values_percentages: [
                38.69,
                41.64,
                37.14,
                37.86,
                35.25,
                37.6,
                36.05,
                37.22,
                38,
                39.09,
                38.39,
                42.04,
                41.95
              ],
              targets_percentages: []
            }
          ],
          values: [
            399120.03,
            23112.03,
            25677.58,
            30789.61,
            28289.52,
            32393.01,
            29755.17,
            33247.73,
            33002.82,
            37743.97,
            41889.6,
            39707,
            43511.99
          ],
          targets: [],
          values_percentages: [
            75.86,
            75.16,
            76.92,
            74.7,
            77.86,
            75.1,
            77.21,
            75.88,
            75.41,
            75,
            75.86,
            76.03,
            75.81
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'CM2',
            type: 'metric'
          },
          name: 'CM2',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Advertising',
                type: 'metric'
              },
              name: 'Advertising',
              children: [],
              values: [
                -29525.07,
                -20.22,
                -3168.87,
                -4080.56,
                -1879.81,
                -1663.22,
                -606.86,
                -1707.22,
                -2716.96,
                -1864.33,
                -3463.43,
                -4205.05,
                -4148.54
              ],
              targets: [],
              values_percentages: [
                5.61,
                0.07,
                9.49,
                9.9,
                5.17,
                3.86,
                1.57,
                3.9,
                6.21,
                3.7,
                6.27,
                8.05,
                7.23
              ],
              targets_percentages: []
            }
          ],
          values: [
            195544.2573,
            10308.5405,
            13278.4894,
            15181.977,
            15483.1594,
            16173.1437,
            15861.2234,
            16938.4767,
            16370.1234,
            18073.6788,
            20692.0163,
            17749.1028,
            19434.3259
          ],
          targets: [],
          values_percentages: [
            37.17,
            33.52,
            39.78,
            36.83,
            42.61,
            37.49,
            41.16,
            38.66,
            37.4,
            35.91,
            37.47,
            33.98,
            33.86
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'CM3',
            type: 'metric'
          },
          name: 'CM3',
          children: [
            {
              type: 'metric',
              metadata: {
                name: 'Other Expenses',
                type: 'metric'
              },
              name: 'Other Expenses',
              children: [],
              values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              targets: [],
              values_percentages: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              targets_percentages: []
            }
          ],
          values: [
            166019.1873,
            10288.3205,
            10109.6194,
            11101.417,
            13603.3494,
            14509.9237,
            15254.3634,
            15231.2567,
            13653.1634,
            16209.3488,
            17228.5863,
            13544.0528,
            15285.7859
          ],
          targets: [],
          values_percentages: [
            31.56,
            33.46,
            30.28,
            26.93,
            37.44,
            33.64,
            39.58,
            34.76,
            31.2,
            32.21,
            31.2,
            25.93,
            26.63
          ],
          targets_percentages: []
        },
        {
          type: 'metric',
          metadata: {
            name: 'Net Profit',
            type: 'metric'
          },
          name: 'Net Profit',
          children: [],
          values: [
            166019.1873,
            10288.3205,
            10109.6194,
            11101.417,
            13603.3494,
            14509.9237,
            15254.3634,
            15231.2567,
            13653.1634,
            16209.3488,
            17228.5863,
            13544.0528,
            15285.7859
          ],
          targets: [],
          values_percentages: [
            31.56,
            33.46,
            30.28,
            26.93,
            37.44,
            33.64,
            39.58,
            34.76,
            31.2,
            32.21,
            31.2,
            25.93,
            26.63
          ],
          targets_percentages: []
        }
      ],
      values: [],
      targets: [],
      values_percentages: [],
      targets_percentages: []
    }
  ],
  product_details: {
    '6367519': {
      id: 6367519,
      asin: 'H515DFT59R',
      name:
        'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
      seller_sku: 'GG-51B4-DTG2',
      marketplace_id: 3,
      smallimage: 'https://m.media-amazon.com/images/I/81NXW0pDhCL._SL75_.jpg',
      variation_type_one: 'size',
      variation_value_one: '20.8 Ounce (Pack of 1)',
      variation_type_two: '',
      variation_value_two: '',
      variation_type_three: '',
      variation_value_three: '',
      variation_type_four: '',
      variation_value_four: '',
      variation_type_five: '',
      variation_value_five: ''
    },
    '6367543': {
      id: 6367543,
      asin: 'A554K46TJL',
      name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
      smallImage:
        'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
      seller_sku: 'BU-4HE3-PV122',
      marketplace_id: 3,
      smallimage: 'https://m.media-amazon.com/images/I/91EfEsRNPrL._SL75_.jpg',
      variation_type_one: 'style',
      variation_value_one: '0 33-Pcs Metric/Inch Tool Roll',
      variation_type_two: '',
      variation_value_two: '',
      variation_type_three: '',
      variation_value_three: '',
      variation_type_four: '',
      variation_value_four: '',
      variation_type_five: '',
      variation_value_five: ''
    }
  }
};

mock.onGet(API.pnl_dashboard.metrics).reply(async config => {
  const { segmentation } = config.params;
  if (segmentation === 'metric,product') {
    return [200, dataResponseByMetricAndProduct];
  }
  if (segmentation === 'product,metric') {
    return [200, dataResponseByProductAndMetric];
  }
  return [200, dataResponseByMetric];
});

const topSalesProducts = {
  '6367519': {
    id: 6367519,
    asin: 'H515DFT59R',
    name:
      'FitFoam Foam Roller - Medium Density Deep Muscle Tissue Massager for Post-Workout Massage',
    smallImage:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/MOWIN-Exercise-Massage-Muscle-Foam-Yoga-Roller.jpg_350x350.jpg',
    seller_sku: 'GG-51B4-DTG2',
    marketplace_id: 3,
    smallimage: 'https://m.media-amazon.com/images/I/81NXW0pDhCL._SL75_.jpg',
    variation_type_one: 'size',
    variation_value_one: '20.8 Ounce (Pack of 1)',
    variation_type_two: '',
    variation_value_two: '',
    variation_type_three: '',
    variation_value_three: '',
    variation_type_four: '',
    variation_value_four: '',
    variation_type_five: '',
    variation_value_five: ''
  },
  '6367543': {
    id: 6367543,
    asin: 'A554K46TJL',
    name: 'Meditown Digital Ear Thermometer - Instant Scan Dual Function Baby Infrared Medical',
    smallImage:
      'https://storage.googleapis.com/staging-amza-210707/products/temp-products/ear%20thermo.jpg',
    seller_sku: 'BU-4HE3-PV122',
    marketplace_id: 3,
    smallimage: 'https://m.media-amazon.com/images/I/91EfEsRNPrL._SL75_.jpg',
    variation_type_one: 'style',
    variation_value_one: '0 33-Pcs Metric/Inch Tool Roll',
    variation_type_two: '',
    variation_value_two: '',
    variation_type_three: '',
    variation_value_three: '',
    variation_type_four: '',
    variation_value_four: '',
    variation_type_five: '',
    variation_value_five: ''
  }
};
mock.onGet(API.pnl_dashboard.top_sales_products).reply(() => {
  return [200, topSalesProducts];
});

const brandsResponse = [
  {
    client_id: 4672,
    name: 'BABR'
  },
  {
    client_id: 4685,
    name: '#4685'
  },
  {
    client_id: 5807,
    name: '#5807'
  },
  {
    client_id: 5820,
    name: '#5820'
  }
];
mock.onGet(API.pnl_dashboard.brands).reply(() => {
  return [200, brandsResponse];
});
